import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import ContactUs from "./pages/ContactUs/ContactUs";
import Home from "./pages/Home/Home";
import Market from "./pages/Market/Market";
import Platform from "./pages/Platform/Platform";
import Trading from "./pages/Trading/Trading";

function Index(props) {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <BrowserRouter>
        <Route
          render={({ location }) => (
            <AnimatePresence mode="wait">
              <Switch location={location} key={location.pathname}>
                <Route path="/home" render={(props) => <Home {...props} />} />
                <Route path="/trading" render={(props) => <Trading {...props} />} />
                <Route path="/markets" render={(props) => <Market {...props} />} />
                <Route path="/platform" render={(props) => <Platform {...props} />} />
                <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
                <Redirect from="/" to="/home" />
              </Switch>
            </AnimatePresence>
          )}
        >
        </Route>
      </BrowserRouter>
    </>
  );
}

export default Index;
