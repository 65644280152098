let en = null;
let zh = null;
let fr = null;
let es = null;

export let translate = (lang, key) => {
    switch (lang) {
        case "EN":
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }

        case "ZH":
            if (zh === null) {
                zh = require("../language/zh").default;
            }
            if (zh[key]) {
                return zh[key];
            } else {
                return key;
            }
        case "FR":
            if (fr === null) {
                fr = require("../language/fr").default;
            }
            if (fr[key]) {
                return fr[key];
            } else {
                return key;
            }
        case "ES":
            if (es === null) {
                es = require("../language/es").default;
            }
            if (es[key]) {
                return es[key];
            } else {
                return key;
            }
        default:
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }
    }
};