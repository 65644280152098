import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function PageHeader(props) {

    return (
        <>
            <div className='header'>
                <Container className='h-100 py-5'>
                    <Row className='align-items-end align-items-sm-center px-0 mx-0 h-100'>
                        <Col lg={9} md={9} xs={12} sm={12} className='px-0'>
                            <Row className='mx-0 px-0 h-100 align-items-lg-center aligns-items-center justify-content-center justify-content-md-start'>
                                <Col lg={6} md={12} xs={12} sm={12} className='px-0 mx-md-0 highresscreen'>
                                    <div className='mt-3'>
                                        {props.title}
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-md-0'>
                                    {props.subtitle ? (
                                        <Row className='px-0 mx-0 justify-content-md-between my-md-5 my-3'>
                                            <Col lg={6} md={12} sm={12} xs={12} className='px-0 mb-0 mb-md-2'>
                                                <div className='content-text-container'>
                                                    {props.subtitle}
                                                </div>
                                            </Col>
                                        </Row>
                                    ) : null}
                                    <Row className='px-0 mx-0 justify-content-md-between'>
                                        <Col lg={12} md={12} sm={12} xs={12} className='px-0 mb-md-0 mb-3 mb-md-2 d-md-none d-block mt-lg-5 mt-md-4'>
                                            <div className='white-divider'></div>
                                        </Col>
                                    </Row>
                                    {props.joinUs || props.demoAccount ? (
                                        <Row className='px-0 mx-0 align-items-start align-items-lg-center'>
                                            <Col lg={6} md={12} sm={12} xs={12} className='px-0 h-100'>
                                                <Row className='px-0 mx-0 w-100 h-100 justify-content-start'>
                                                    {props.joinUs ? (
                                                        <Col lg={6} md={6} sm={props.demoAccount ? 6 : 12} xs={props.demoAccount ? 6 : 12} className='pl-0 h-100 pr-2'>
                                                            <Link to="/contact-us" className="text-decoration-none">
                                                                <div className='text-center yellowBtn px-1 py-3 h-100'>
                                                                    <span className='yellowBtnText'>Create Account</span>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    ) : null}
                                                    {props.demoAccount ? (
                                                        <Col lg={6} md={6} sm={props.joinUs ? 6 : 12} xs={props.joinUs ? 6 : 12} className='pr-0 h-100 pl-2'>
                                                            <Link to="/contact-us" className="text-decoration-none">
                                                                <div className='text-center redBtn px-1 py-3 h-100'>
                                                                    <span className='redBtnText'>Demo Account</span>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(PageHeader);