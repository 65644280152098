import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

const list = [
    { name: 'PC Download', link: '', icon: require('../../../assets/img/platform/pc_inactive.svg').default, iconActive: require('../../../assets/img/platform/pc_active.svg').default },
    { name: 'Android Download', link: '', icon: require('../../../assets/img/platform/android_inactive.svg').default, iconActive: require('../../../assets/img/platform/android_active.svg').default },
    { name: 'IOS Download', link: '', icon: require('../../../assets/img/platform/ios_inactive.svg').default, iconActive: require('../../../assets/img/platform/ios_active.svg').default },
]

function Download(props) {
    return (
        <>
            <div className='download py-0 py-md-5'>
                <Container className='py-5 my-5'>
                    <Row className="px-0 mx-0 justify-content-center align-items-center">
                        <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                            <div className='text-left text-md-center reveal fade-left'>
                                <span className='section-title'><span className="text-red">Download</span> Meta Trader 5</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className='px-0 mt-5'>
                            <Row className='px-0 mx-0 justify-content-center'>
                                {list.map((item, i) => (
                                    <Col lg={3} md={4} sm={12} xs={12} key={i} className={`px-0 px-md-2 ${i !== list.length - 1 ? 'mb-3' : ''}`}>
                                        <div className="assetBox px-md-4 px-4 py-4 d-flex align-items-center w-100">
                                            <div className='w-100'>
                                                <div className='mb-lg-4 mb-3'>
                                                    <img src={item.icon} className='icon inactive' alt={item.name} />
                                                    <img src={item.iconActive} className='icon active' alt={item.name} />
                                                </div>
                                                <span className='assetName'>{item.name}</span>
                                            </div>
                                            <div className='corner'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                                                    <rect width="35" height="35" transform="matrix(1 1.74846e-07 1.74846e-07 -1 35 70)" fill="#CDA351" />
                                                    <rect opacity="0.8" width="35" height="35" transform="matrix(1 1.74846e-07 1.74846e-07 -1 35 35)" fill="#E6E6F3" />
                                                    <rect width="35" height="35" transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 35)" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Download);