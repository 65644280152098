import { useEffect, useLayoutEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux';
import "../../../assets/css/home/popularmarket.scss"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Marquee from "react-fast-marquee";
import { translate } from 'views/utils/translate';

const list = [
    { name: 'FOREX TRADING', message: 'With over $4 trillion of Forex traded daily, currency pairs are some of the most liquid markets to trade.' },
    { name: 'GOLD TRADING', message: 'Seen a safe-haven assets, Metals are traders’ go-to investment when heavy volatility hits the markets.' },
    { name: 'INDICES TRADING', message: "From Wall Street to the FTSE, trade all the major stock Indices from across the world." },
    { name: 'ENERGY TRADING', message: "With strong political and environmental significance, embrace the volatility of the Energies markets." },
]

const marqueData = ["Forex", "Gold", "Energy", "Indices"];
 
function PopularMarket(props) {
    const [pageNumber, setPageNumber] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(2);
    const [pageCount, setPageCount] = useState(Math.ceil(list.length / itemPerPage));
    const [data, setData] = useState([]);

    useEffect(() => {
        setPageNumber(1);
        let total = Math.ceil(list.length / itemPerPage);
        setPageCount(total);
    }, [itemPerPage])

    useLayoutEffect(() => {
        async function updateSize() {
            if (window.innerWidth <= 767) {
                await setItemPerPage(2)
                setPageCount(Math.ceil(list.length / 2));
                setPageNumber(1)
            } else {
                await setItemPerPage(4)
                setPageCount(Math.ceil(list.length / 4));
                setPageNumber(1)
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    useEffect(() => {
        const startIndex = (pageNumber - 1) * itemPerPage;
        const endIndex = startIndex + itemPerPage;
        const pageItems = list.slice(startIndex, endIndex);
        setData(pageItems);
    }, [pageNumber, itemPerPage])

    // const getData = (page) => {
    //     const startIndex = (page - 1) * itemPerPage;
    //     const endIndex = startIndex + itemPerPage;
    //     const pageItems = list.slice(startIndex, endIndex);
    //     setData(pageItems);
    // }

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const renderPagination = () => {
        var elements = [];
        for (let i = 0; i < pageCount; i++) {
            elements.push(
                <div key={i} className={`mr-2 page-bullet ${pageNumber - 1 === i ? 'active' : ''}`}></div>
            );
        }
        return elements;
    }

    const nextSlide = () => {
        if (pageNumber !== pageCount) {
            setPageNumber(pageNumber + 1);
        } else if (pageNumber === pageCount) {
            setPageNumber(1);
        }
    }

    const prevSlide = () => {
        console.log(pageNumber)
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        } else {
            setPageNumber(pageCount);
        }
    }

    function leftScroll() {
        const left = document.querySelector(".comment-list");
        left.scrollBy(-200, 0);
    }
    function rightScroll() {
        const right = document.querySelector(".comment-list");
        right.scrollBy(200, 0);
    }
    return (
        <>
            <div className='client position-relative'>
                <Row className='px-0 mx-0 background-container'>
                    <Col lg={6} md={12} xs={12} sm={12} className='px-0 bgWhite'>
                        <Container className='h-100 px-0'>

                        </Container>
                    </Col>
                    <Col lg={6} md={12} xs={12} sm={12} className='px-0 bgRed'>
                        <Container className='h-100 px-0'>

                        </Container>
                    </Col>
                    <Col lg={6} md={12} xs={12} sm={12} className='px-0'>
                    </Col>
                </Row>
                <div className='client-content'>
                    <Container className='h-100'>
                        <Row className='align-items-start px-0 mx-0 w-100 h-100 clientPaddingTop'>
                            <Col lg={5} md={12} xs={12} sm={12} className='px-0 sectionOne'>
                                <div className='text-container'>
                                    <div className='d-flex flex-column w-100 align-items-start'>
                                        <div>
                                            <div>
                                                <span className='client-hearclientsays'>Access Popular {'\n'}Markets & Products {'\n'}Worldwide.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-none d-lg-block'>
                                        <div className='d-flex mt-3'>
                                            <div className='mr-3 arrow'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" onClick={() => leftScroll()}>
                                                    <circle cx="30" cy="30" r="28.5" transform="rotate(-180 30 30)" stroke="#555555" strokeWidth="3" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M33.6576 40.9335L23.7895 31.0654C23.1839 30.4599 23.1839 29.4781 23.7895 28.8725L33.6576 19.0044C34.2632 18.3988 35.245 18.3988 35.8505 19.0044C36.4561 19.6099 36.4561 20.5917 35.8505 21.1973L27.4303 29.6175C27.4565 29.7304 27.4703 29.8481 27.4703 29.969C27.4703 30.0898 27.4565 30.2075 27.4303 30.3204L35.8505 38.7406C36.4561 39.3462 36.4561 40.328 35.8505 40.9335C35.245 41.5391 34.2632 41.5391 33.6576 40.9335Z" fill="#555555" />
                                                </svg>
                                            </div>
                                            <div className='arrow'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" onClick={() => rightScroll()}>
                                                    <circle cx="30" cy="30" r="28.5" stroke="#555555" strokeWidth="3" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M26.3424 19.0665L36.2105 28.9346C36.8161 29.5401 36.8161 30.5219 36.2105 31.1275L26.3424 40.9956C25.7368 41.6012 24.755 41.6012 24.1495 40.9956C23.5439 40.3901 23.5439 39.4083 24.1495 38.8027L32.5697 30.3825C32.5435 30.2696 32.5297 30.1519 32.5297 30.031C32.5297 29.9102 32.5435 29.7925 32.5697 29.6796L24.1495 21.2594C23.5439 20.6538 23.5439 19.672 24.1495 19.0665C24.755 18.4609 25.7368 18.4609 26.3424 19.0665Z" fill="#555555" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7} md={12} xs={12} sm={12} className='px-0 sectionTwo position-relative'>
                                <div className='d-none d-lg-block'>
                                    <div className='comment-list'>
                                        {list.map((item, i) => (
                                            <div key={i} className='comment-box mr-3 px-3 py-4 d-flex align-items-stretch'>
                                                <div className='d-flex flex-column'>
                                                    <div className='d-flex align-items-center' style={{ flex: 1 }}>
                                                        <span className='client-name'>{translate(props.lang,item.name)}</span>
                                                    </div>
                                                    <div className='d-flex align-items-start py-3' style={{ flex: 3 }}>
                                                        <span className='client-message lh-base'>{translate(props.lang,item.message)}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center' style={{ flex: 1 }}>
                                                        <Link to="/markets" className="text-decoration-none">
                                                            <span className='client-position'>{translate(props.lang,"Learn More")} {'>'}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='d-block d-lg-none'  {...handlers}>
                                    <div className='client-mobile-container'>
                                        <Row className='justify-content-center justify-content-md-start align-items-stretch px-0 mx-0'>
                                            {data.map((item, i) => (
                                                <Col sm={6} md={6} xs={12} key={i} className={`mb-4 ${itemPerPage === 2 ? 'px-0' : i % 2 === 0 ? 'pl-0' : 'pr-0'}`}>
                                                    <div key={i} className='h-100 comment-box px-3 py-5'>
                                                        <Row className="align-items-center h-100 mx-0 px-0">
                                                            <Col>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='d-flex align-items-center' style={{ flex: 1 }}>
                                                                        <span className='client-name'>{translate(props.lang,item.name)}</span>
                                                                    </div>
                                                                    <div className='d-flex align-items-start py-3' style={{ flex: 3 }}>
                                                                        <span className='client-message lh-base'>{translate(props.lang,item.message)}</span>
                                                                    </div>
                                                                    <div className='d-flex align-items-center' style={{ flex: 1 }}>
                                                                        <Link to="/markets" className="text-decoration-none">
                                                                            <span className='client-position'>{translate(props.lang, "Learn More")} {'>'}</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <div className='pagination text-center w-100 mt-2 d-block d-md-none'>
                                        <Row className='px-0 mx-auto align-items-center'>
                                            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                                <div className='d-flex justify-content-center justify-content-md-start align-items-center'>
                                                    {pageCount > 0 ? renderPagination() : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='marquee-absolute' style={{ width: '100%', overflow: 'hidden'}}>
                    <Marquee>
                        <div className='d-flex align-items-center justify-content-between'>
                            {marqueData.map((item, i) => (
                                <div key={i} className='px-5 py-3'>
                                    <span className='marquee-name'>{item}</span>
                                </div>
                            ))}
                        </div>
                    </Marquee>
                    <Marquee direction='right'>
                        <div className='d-flex align-items-center justify-content-between'>
                            {marqueData.reverse().map((item, i) => (
                                <div key={i} className='px-5 py-3'>
                                    <span className='marquee-name'>{item}</span>
                                </div>
                            ))}
                        </div>
                    </Marquee>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(PopularMarket);
