import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import { connect } from 'react-redux';
import ScrollToTop from "react-scroll-to-top";
import PageHeader from 'views/pages/Component/PageHeader';
import TradingMarquee from 'views/pages/Component/TradingMarquee';
import "../../../assets/css/company/contactus.scss";
import "../../../assets/css/company/company.scss";
import Footer from '../Component/Footer';
import HeaderNavBar from '../Component/HeaderNavBar';
import ContactForm from './ContactForm';
import SEO from '../Component/Seo';
import TradeRiskFree from '../Component/TradeRiskFree';

function ContactUs(props) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [history])
    return (
        <>
            <SEO
                title='Contact Us - Bost Forex'
                description='Our expert support team is with you every step of the way and here to help out with all your trading needs.'
            />
            <HeaderNavBar />
            <div className='contents'>
                <div className="company contactus">
                    <PageHeader
                        title={<span className='head-big-title'><span className='text-yellow'>Contact Us</span></span>}
                        subtitle={
                            <>
                                <div className='content-text-container'>
                                    <span className='cu-text lh-base'>Our expert support team is with you every step of the way and here to help out with all your trading needs.</span>
                                </div>
                            </>
                        }
                        joinUs={false}
                        demoAccount={false}
                    />
                    <div className='pb-5'>
                        <ContactForm />
                    </div>
                    <TradeRiskFree />
                </div>
                <Footer />
                <TradingMarquee />
                <ScrollToTop smooth className="scrolltotop d-flex align-items-center justify-content-center" component={<div><FaArrowUp color="#fff" /></div>} />
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContactUs);