import React from "react";
import ReactDOM from "react-dom/client";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "assets/css/custom.scss";
// pages for this kit
import Index from "views/Index.js";
import { Provider } from 'react-redux';
import store from "views/store/store";
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <Index />
    </Provider>
  </HelmetProvider>
);
