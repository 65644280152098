import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    Col, Container, Row
} from "reactstrap";
import menu from 'views/utils/menu2';
import { translate } from "views/utils/translate";
import "../../../assets/css/common/navbar.scss";

const language = [
    { name: 'English', value: 'EN', icon: require('../../../assets/img/language/en.png') },
    // { name: '简体中文', value: 'ZH', icon: require('../../../assets/img/language/cn.png') },
]

// const socialLink = [
//     { name: 'Facebook', icon: require('../../../assets/img/footer/facebook.svg').default, url: '' },
//     { name: 'Instagram', icon: require('../../../assets/img/footer/instagram.svg').default, url: '' },
//     { name: 'Telegram', icon: require('../../../assets/img/footer/telegram.svg').default, url: '' },
//     { name: 'Youtube', icon: require('../../../assets/img/footer/youtube.svg').default, url: '' },
//     { name: 'Whatsapp', icon: require('../../../assets/img/footer/whatsapp.svg').default, url: '' },
//     { name: 'LinkedIn', icon: require('../../../assets/img/footer/linkedin.svg').default, url: '' },
// ]

function Drawer(props) {
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <>
            <div className='drawer-menu'>
                <Container className='vh-100 position-relative'>
                    <Row className='mx-0 px-0 h-100 aligns-items-center'>
                        <Col lg={12} md={12} xs={12} sm={12} className='px-0 pt-5 mx-0 h-100 d-flex align-items-start justify-content-center'>
                            <div className='w-100 pt-5'>
                                <ul>
                                {menu.map((item, i) => (
                                <li key={i} className='cursor-pointer py-3 px-0 w-100 text-left'>
                                    <a href={item.pathName} className="text-left p-0 mx-0'">
                                        <span className={`parent-menu-text ${location.pathname === item.pathName ? 'active': ''}`}>{translate(props.lang, item.menu)}</span>
                                    </a>
                                </li>
                                ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="drawer-copyright">
                        <Row className='align-items-center justify-content-center mt-4 px-0 mx-0 '>
                            <Col lg={8} md={6} sm={12} xs={12} className='px-0 mb-lg-0 h-100'>
                                <img src={require('../../../assets/img/headerBackground/redDivider.svg').default} alt="divider" className='img-fluid w-100' />
                            </Col>
                            <Col lg={4} md={6} sm={12} xs={12} className='px-0'>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    {socialLink.map((item, i) => (
                                        <a key={i} href={item.url} className="social-icon px-2">
                                            <img src={item.icon} alt={item.name} className="img-fluid" />
                                        </a>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Drawer);

