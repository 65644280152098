import { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Input } from 'reactstrap';
import Select from "react-select";
import { translate } from 'views/utils/translate';
import FormSelectStyle from './FormSelectStyle';

const supportTypeList = [
    { label: 'Customer Service', value: 'Customer Service' },
    { label: 'Marketing ', value: 'Marketing ' },
    { label: 'Sales', value: 'Sales' },
]
function ContactForm(props) {
    const errorObj = {
        name: '',
        supportType: '',
        phone: '',
        email: '',
        message: '',
    };
    const [errors, setErrors] = useState(errorObj);
    const [formData, setFormData] = useState({
        name: '',
        supportType: '',
        phone: '',
        email: '',
        message: '',
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue.trim();
        setFormData(newFormData);
    }

    const handleOptionSelect = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitform = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.email) {
            errorMsg.email = "This field is required";
            check = false;
        }
        if (!formData.name) {
            errorMsg.name = "This field is required";
            check = false;
        }
        if (!formData.phone) {
            errorMsg.phone = "This field is required";
            check = false;
        }
        if (!formData.supportType) {
            errorMsg.supportType = "This field is required";
            check = false;
        }
        if (!formData.message) {
            errorMsg.message = "This field is required";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {

        }
    }

    return (
        <>
            <div className='excellence'>
                <Container className='py-md-5 pt-5'>
                    <Row className="px-0 mx-0 justify-content-center align-items-center">
                        <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                            <Row className='mx-0 justify-content-start w-100 px-0'>
                                <Col lg={6} md={10} xs={12} sm={12} className='px-0 mx-0 highresscreen'>
                                    <div className='reveal fade-left'>
                                        <span className='cu-title'><span className='text-red'>{translate(props.lang, "Need a hand?")} </span>{translate(props.lang, "Don’t hesitate to get in touch with us for any of your trading enquiries.")}</span>
                                    </div>
                                    <div className='my-4 reveal fade-left'>
                                        <span className='cu-subtitle lh-base'>{translate(props.lang, "For general enquiries")}: support@bostforex.com</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mb-4 px-0'>
                                    <div className={`groupLabel-input mb-1 ${errors.username ? 'errorBorder' : ''}`}>
                                        <Row className='px-0 mx-0 align-items-center'>
                                            <Col lg={3} md={4} sm={12} xs={12} className='px-0 py-2'>
                                                <span className='input-label'>{translate(props.lang, "Full Name")}<span className='red'>*</span></span>
                                            </Col>
                                            <Col lg={9} md={8} sm={12} xs={12} className='px-0'>
                                                <div className={`input-group `}>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        placeholder={translate(props.lang, "Full Name")}
                                                        className="form-control input-transparent py-1"
                                                        value={formData.name}
                                                        autoComplete="off"
                                                        onChange={e => handleAddFormChange(e)}
                                                    />
                                                </div>
                                                <span className='error'>{errors.name}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mb-4 px-0'>
                                    <div className={`groupLabel-input mb-1 ${errors.username ? 'errorBorder' : ''}`}>
                                        <Row className='px-0 mx-0 align-items-center'>
                                            <Col lg={3} md={4} sm={12} xs={12} className='px-0 py-2'>
                                                <span className='input-label'>{translate(props.lang, "Your phone number")}<span className='red'>*</span></span>
                                            </Col>
                                            <Col lg={9} md={8} sm={12} xs={12} className='px-0'>
                                                <div className={`input-group `}>
                                                    <Input
                                                        type="text"
                                                        name="phone"
                                                        placeholder={''}
                                                        className="form-control input-transparent py-1"
                                                        value={formData.phone}
                                                        autoComplete="off"
                                                        onChange={e => handleAddFormChange(e)}
                                                    />
                                                </div>
                                                <span className='error'>{errors.phone}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mb-4 px-0'>
                                    <div className={`groupLabel-input mb-1 ${errors.username ? 'errorBorder' : ''}`}>
                                        <Row className='px-0 mx-0 align-items-center'>
                                            <Col lg={3} md={4} sm={12} xs={12} className='px-0 py-2'>
                                                <span className='input-label'>{translate(props.lang, "Your e-mail")}<span className='red'>*</span></span>
                                            </Col>
                                            <Col lg={9} md={8} sm={12} xs={12} className='px-0'>
                                                <div className={`input-group `}>
                                                    <Input
                                                        type="text"
                                                        name="email"
                                                        placeholder={'bost@gmail.com'}
                                                        className="form-control input-transparent py-1 text-lowercase"
                                                        value={formData.email}
                                                        autoComplete="off"
                                                        onChange={e => handleAddFormChange(e)}
                                                    />
                                                </div>
                                                <span className='error'>{errors.email}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mb-4 px-0'>
                                    <div className={`groupLabel-input mb-1 ${errors.username ? 'errorBorder' : ''}`}>
                                        <Row className='px-0 mx-0 align-items-center'>
                                            <Col lg={3} md={4} sm={12} xs={12} className='px-0 py-2'>
                                                <span className='input-label'>{translate(props.lang, "Your message")}<span className='red'>*</span></span>
                                            </Col>
                                            <Col lg={9} md={8} sm={12} xs={12} className='px-0'>
                                                <div className={`input-group `}>
                                                    <Input
                                                        type="text"
                                                        name="message"
                                                        placeholder={translate(props.lang, "Say something")}
                                                        className="form-control input-transparent py-1"
                                                        value={formData.message}
                                                        autoComplete="off"
                                                        onChange={e => handleAddFormChange(e)}
                                                    />
                                                </div>
                                                <span className='error'>{errors.message}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='mt-5 px-0'>
                                    <div className='text-center redBtn py-3 h-100' onClick={() => submitform()}>
                                        <span className='redBtnText'>{translate(props.lang, "Submit")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContactForm);