import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

function GlobalMarket(props) {
    return (
        <>
            <div className='py-5 globalmarket'>
                <div className='py-lg-5'>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-center px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pr-md-3 text-center text-md-left mb-4 mb-md-0'>
                                        <img src={require('../../../assets/img/platform/mockup2.png')} className='img-fluid' alt="mockup" />
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 pl-md-3 mx-0'>
                                        <div className='reveal fade-top'>
                                            <span className='section-title'>Access the global markets on the <span className='text-red'>No.1 trading platform.</span></span>
                                        </div>
                                        <div className='my-4 reveal fade-left'>
                                            <span className='section-description lh-base'>All of our accounts offer the MT5 trading platform, so with Bost you’ll be using the world’s best trading technology to tackle the markets. MT5 is a traditional platform that’s been fundamental to the success of professional traders for decades, but its efficient user interface combined and our comprehensive support mean it’s also ideal for those who are newer to the markets. What’s more, you can trade anytime, anywhere with our MT5 app that’s available to download for free on both iOS and Android, so you can stay connected to the markets on the go.</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(GlobalMarket);