import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import ScrollToTop from "react-scroll-to-top";
import { connect } from 'react-redux';
import Footer from '../Component/Footer';
import TradeRiskFree from '../Component/TradeRiskFree';
import "../../../assets/css/platform/platform.scss";
import HeaderNavBar from '../Component/HeaderNavBar';
import TradingMarquee from '../Component/TradingMarquee';
import PageHeader from '../Component/PageHeader';
import MT5 from './MT5';
import GlobalMarket from './GlobalMarket';
import Download from './Download';
import SEO from '../Component/Seo';

function Platform(props) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [history])
    return (
        <>
            <SEO
                title='Platform & Tools - Bost Forex'
                description='Connect with a global community and trade using MT5, the world’s most popular trading platform. Analyse, track and trade using a range of advanced tools, so you can effectively take on the markets.'
            />
            <HeaderNavBar />
            <div className='contents'>
                <div className="platform platform">
                    <PageHeader
                        title={<span className='head-big-title'><span className='text-yellow'>Platforms & Tools</span></span>}
                        subtitle={<span className='content-text lh-base'>From Gold to Forex pairs, we offer over 50 of the most popular markets. Allow us to connect you to them and help you discover your next trading opportunity.</span>}
                        joinUs={true}
                        demoAccount={true}
                    />
                    <MT5 />
                    <GlobalMarket />
                    <Download />
                    {/* <Header />
        <Reason />
        <Assets /> */}
                    <TradeRiskFree />
                </div>
                <Footer />
                <TradingMarquee />
                <ScrollToTop smooth className="scrolltotop d-flex align-items-center justify-content-center" component={<div><FaArrowUp color="#fff" /></div>} />
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Platform);