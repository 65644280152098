import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

function MT5(props) {
    return (
        <>
            <div className='py-5'>
                <div className='mt5'>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-center px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 pl-md-3 mx-0 order-2 order-lg-1'>
                                        <div className='reveal fade-top'>
                                            <span className='section-title'><span className='text-red'>Meta Trader 5</span></span>
                                        </div>
                                        <div className='my-4 reveal fade-left'>
                                            <span className='section-description lh-base'>Discover the world’s most popular trading platform - MetaTrader4. Connect to the markets with instant access, trading tools and enhanced customization functionality.</span>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pr-md-3 text-center text-md-right order-1 order-lg-2 mb-4 mb-md-0'>
                                        <img src={require('../../../assets/img/platform/mockup1.png')} className='img-fluid' alt="mockup" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(MT5);