const EN = {
    "Home": 'Home',
    "Trading": "Trading",
    "Markets": 'Markets',
    "Platforms & Tools": "Platforms & Tools",
    "Contact Us": 'Contact Us',
    "Create Account": "Create Account",
    "Demo Account": "Demo Account",
    "Commission - Free with No Deposit Fees.": "Commission - Free with No Deposit Fees.",
    "Open Your Account Now!": "Open Your Account Now!",
    "Trade Shares, Indices & Stocks": "Trade Shares, Indices & Stocks",
    "With tight spreads, high leverage and quick execution of trades, we have well over a decade of experience connecting traders across the world to global markets. whether you’re new to the markets or an experienced trader, we can offer you the ideal trading conditions and prices for you to effectively take on the markets. so, join the hundreds of thousands already a part of the bost forex network and allow us to connect you to the global markets.": "with tight spreads, high leverage and quick execution of trades, we have well over a decade of experience connecting traders across the world to global markets. whether you’re new to the markets or an experienced trader, we can offer you the ideal trading conditions and prices for you to effectively take on the markets. so, join the hundreds of thousands already a part of the bost forex network and allow us to connect you to the global markets.",
    "All of our accounts offer trading through the MT5 platform, so you have the opportunity to use the world’s best trading technology to tackle the markets, regardless of experience.": "All of our accounts offer trading through the MT5 platform, so you have the opportunity to use the world’s best trading technology to tackle the markets, regardless of experience.",
    "MT5 is a traditional platform that’s been fundamental to the success of professional traders for decades, but its efficient user interface combined with our comprehensive support resources mean it’s also ideal for those who are newer to the markets.": "MT5 is a traditional platform that’s been fundamental to the success of professional traders for decades, but its efficient user interface combined with our comprehensive support resources mean it’s also ideal for those who are newer to the markets.",
    "Automate your trade": "Automate your trade",
    "Simple-to-use interface": "Simple-to-use interface",
    "Analyse the markets": "Analyse the markets",
    "Create your own algorithms and automate your trades through customisable EAs (expert advisors).": "Create your own algorithms and automate your trades through customisable EAs (expert advisors).",
    "Navigate your way through the markets with the simple, efficient interface that MT5 offers.": "Navigate your way through the markets with the simple, efficient interface that MT5 offers.",
    "Use the array of analytical tools available to you and analyse every aspect of any market you trade.": "Use the array of analytical tools available to you and analyse every aspect of any market you trade.",
    'FOREX TRADING': "FOREX TRADING",
    "GOLD TRADING": "GOLD TRADING",
    "INDICES TRADING": 'INDICES TRADING',
    "ENERGY TRADING": "ENERGY TRADING",
    "With over $4 trillion of Forex traded daily, currency pairs are some of the most liquid markets to trade.": "With over $4 trillion of Forex traded daily, currency pairs are some of the most liquid markets to trade.",
    "Seen a safe-haven assets, Metals are traders’ go-to investment when heavy volatility hits the markets.": "Seen a safe-haven assets, Metals are traders’ go-to investment when heavy volatility hits the markets.",
    "From Wall Street to the FTSE, trade all the major stock Indices from across the world.": "From Wall Street to the FTSE, trade all the major stock Indices from across the world.",
    "With strong political and environmental significance, embrace the volatility of the Energies markets.": "With strong political and environmental significance, embrace the volatility of the Energies markets.",
    "Leverage 1:1000": "Leverage 1:1000",
    "0.005 Execution Speed": "0.005 Execution Speed",
    "Spreads from 0.5 Pips": "Spreads from 0.5 Pips",
    "No hidden fees": "No hidden fees",
    "Gain greater exposure to the markets with our high leverage of up to 1:1000.": 'Gain greater exposure to the markets with our high leverage of up to 1:1000.',
    "Nullify the impact of slippage and get exact prices with our instant execution on all trades.": "Nullify the impact of slippage and get exact prices with our instant execution on all trades.",
    "Starting from 0.5 pips, trade with some of the tightest spreads in the industry.": "Starting from 0.5 pips, trade with some of the tightest spreads in the industry.",
    "Enjoy trading all the most popular, global markets without any hidden fees.": "Enjoy trading all the most popular, global markets without any hidden fees.",
    "Choose your account type and start trading today!": "Choose your account type and start trading today!",
    "or": "or",
    "register for free demo.": "register for free demo.",
    "Should you have any question about the plan?": "Should you have any question about the plan?",
    "Contact Us.": "Contact Us.",
    "Demo Account": "Demo Account",
    "Find the right account for you.": "Find the right account for you.",
    "Try it out with our free Demo account.": "Try it out with our free Demo account.",
    "Trade over 50 markets on MT5 now with our Standard account and experience all the thrills of live trading.Not ready to trade for real?": "Trade over 50 markets on MT5 now with our Standard account and experience all the thrills of live trading. Not ready to trade for real?",
    "Learn More": "Learn More",
    "TRADE RISK-FREE WITH OUR DEMO ACCOUNT": 'TRADE RISK-FREE WITH OUR DEMO ACCOUNT',
    "Connect to all the major markets in the MT5 environment with our Demo account, and experience the markets risk-free.": 'Connect to all the major markets in the MT5 environment with our Demo account, and experience the markets risk-free.',
    "Register For A Demo Account": 'Register For A Demo Account',
    "Free 30 days Trial  |  Exclusive Support  |  No Fees": "Free 30 days Trial  |  Exclusive Support  |  No Fees",
    "For general enquiries": "For general enquiries",
    "Your phone number": "Your phone number",
    "Full Name": "Full Name",
    "Your e-mail": 'Your e-mail',
    "Your message": "Your message",
    "Submit": 'Submit',
    "Say something": 'Say something',
    "Need a hand?": "Need a hand?",
    "Don’t hesitate to get in touch with us for any of your trading enquiries.": "Don’t hesitate to get in touch with us for any of your trading enquiries.",
}

export default EN;