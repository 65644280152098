import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, Container, Row } from 'reactstrap'
import { translate } from 'views/utils/translate'

function Header(props) {
  return (
    <>
      <div className='header'>
        <Container className='h-100 py-5'>
          <Row className='align-items-center align-items-md-center px-0 mx-0 h-100'>
            <Col lg={6} md={6} xs={12} sm={12} className='px-0 order-md-1 order-2'>
              <Row className='mx-0 px-0 h-100 align-items-lg-center aligns-items-center justify-content-center justify-content-md-start'>
                <Col lg={10} md={12} xs={12} sm={12} className='px-0 mx-md-0 highresscreen'>
                  <div className='mt-3'>
                    <span className='head-big-title'>Connect to a world of trading <span className='text-yellow'>opportunities.</span></span>
                  </div>
                </Col>
                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-md-0 mt-3 mt-md-5 mt-md-4'>
                  <Row className='px-0 mx-0 justify-content-md-between'>
                    <Col lg={12} md={12} sm={12} xs={12} className='px-0 mb-md-0 mb-3 mb-md-2'>
                      <div className='content-text-container'>
                        <span className='content-text d-block'>{translate(props.lang, "Trade Shares, Indices & Stocks")}</span>
                        <span className='content-text d-block'>{translate(props.lang, "Commission - Free with No Deposit Fees.")}</span>
                        <span className='content-text d-block'>{translate(props.lang, "Open Your Account Now!")}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='px-0 mx-0 justify-content-md-between'>
                    <Col lg={12} md={12} sm={12} xs={12} className='px-0 mb-md-0 mb-3 mb-md-2 d-md-none d-block mt-lg-5 mt-md-4'>
                      <div className='white-divider'></div>
                    </Col>
                  </Row>
                  <Row className='px-0 mx-0 align-items-start align-items-lg-center mt-lg-5 mt-md-4'>
                    <Col lg={9} md={12} sm={12} xs={12} className='px-0 h-100'>
                      <Row className='px-0 mx-0 w-100 h-100'>
                        <Col lg={6} md={6} sm={6} xs={6} className='pl-0 pr-2 h-100 mb-3 mb-md-0'>
                          <Link to="/contact-us" className="text-decoration-none">
                            <div className='text-center goldBtn px-1 py-3 h-100 '>
                              <span className='goldBtnText'>{translate(props.lang, "Create Account")}</span>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='px-0 pr-0 pl-2 h-100 mb-3 mb-md-0'>
                          <Link to="/contact-us" className="text-decoration-none">
                            <div className='text-center redBtn px-1 py-3 h-100'>
                              <span className='redBtnText'>{translate(props.lang, "Demo Account")}</span>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} xs={12} sm={12} className='px-1 order-md-2 order-1 mt-md-0 mt-3'>
              <img src={require('../../../assets/img/home/mockup1.webp')} className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(Header);