import { Col, Container, Row } from 'reactstrap';
import "../../../assets/css/trading/withdraw.scss";

function Deposit() {
  return (
    <>
      <div className='deposit d-flex align-items-center'>
        <Container className='py-5 position-relative'>
          <Row className='mx-0 px-0 align-items-center h-100 py-5'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              <Row className='mx-0 px-0 align-items-lg-center align-items-center h-100'>
                <Col lg={5} md={6} sm={12} xs={12} className='reveal fade-left px-0'>
                  <div className='position-relative mockup-form-container px-md-4 px-lg-5 px-3'>
                    <div className='user-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="97" height="96" viewBox="0 0 97 96" fill="none">
                        <path d="M48.5 96C75.2858 96 97 74.5097 97 48C97 21.4903 75.2858 0 48.5 0C21.7142 0 0 21.4903 0 48C0 74.5097 21.7142 96 48.5 96Z" fill="#FC0226" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.69 46.7366C47.2934 46.8167 47.9037 46.8569 48.5171 46.8559C49.1228 46.855 49.7242 46.814 50.3179 46.7345C55.467 47.1462 60.339 49.3138 64.0966 52.9019C68.2899 56.9061 70.7716 62.3702 71.0243 68.1549H26.0101C26.2628 62.3702 28.7445 56.9061 32.9378 52.9019C36.6889 49.3199 41.5507 47.1536 46.69 46.7366ZM53.6311 45.8587C55.3193 45.1772 56.8704 44.16 58.1821 42.8497C60.7445 40.2899 62.1837 36.8206 62.1837 33.2034C62.1837 30.5022 61.3803 27.8618 59.8753 25.6162C58.3703 23.3706 56.2313 21.6207 53.729 20.588C51.2266 19.5553 48.4733 19.2862 45.8175 19.8147C43.1618 20.3432 40.7229 21.6456 38.8094 23.5571C36.896 25.4686 35.594 27.9032 35.0683 30.553C34.5426 33.2027 34.8167 35.9484 35.856 38.4428C36.8952 40.9371 38.653 43.0678 40.9067 44.5654C41.6847 45.0823 42.5096 45.5158 43.3678 45.8617C38.8567 46.8194 34.6684 49.0271 31.3101 52.2797C26.7033 56.7414 24.0209 62.8179 23.832 69.2198V70.2847H73.181V69.2198C72.9922 62.8179 70.3098 56.7414 65.703 52.2797C62.3412 49.0237 58.1476 46.8148 53.6311 45.8587ZM42.0909 23.635C43.9939 22.3705 46.2304 21.6977 48.5171 21.702V21.6594C50.0342 21.6622 51.5359 21.963 52.9364 22.5446C54.337 23.1263 55.609 23.9774 56.6797 25.0493C57.7505 26.1213 58.5991 27.3931 59.1771 28.7922C59.755 30.1913 60.0511 31.6902 60.0483 33.2034C60.0525 35.4841 59.378 37.7149 58.1102 39.613C56.8424 41.5112 55.0384 42.9914 52.9266 43.8661C50.8148 44.7409 48.4902 44.9709 46.2472 44.527C44.0043 44.083 41.9439 42.9852 40.3269 41.3724C38.71 39.7597 37.6093 37.7046 37.1642 35.4674C36.7192 33.2302 36.9497 30.9116 37.8268 28.8053C38.7038 26.6989 40.1878 24.8995 42.0909 23.635Z" fill="white" />
                      </svg>
                    </div>
                    <div className='mockup-input-container d-flex align-items-center'>
                      <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
                          <path d="M46.92 0.867188H5.08594C2.32451 0.867188 0.0859375 3.10576 0.0859375 5.86719V47.5676C0.0859375 50.329 2.32451 52.5676 5.08593 52.5676H46.92C49.6814 52.5676 51.92 50.329 51.92 47.5676V5.86719C51.92 3.10576 49.6814 0.867188 46.92 0.867188Z" fill="#FC0226" />
                          <path d="M27.899 25.7625C28.6479 25.384 29.2779 24.8075 29.7204 24.0959C30.1628 23.3843 30.4005 22.5651 30.4076 21.7278C30.4598 21.1027 30.3814 20.4736 30.1774 19.8803C29.9734 19.2869 29.6482 18.7422 29.2223 18.2806C28.7965 17.8191 28.2792 17.4506 27.7033 17.1986C27.1274 16.9465 26.5054 16.8164 25.8765 16.8164C25.2476 16.8164 24.6255 16.9465 24.0496 17.1986C23.4737 17.4506 22.9565 17.8191 22.5306 18.2806C22.1048 18.7422 21.7796 19.2869 21.5755 19.8803C21.3715 20.4736 21.2931 21.1027 21.3453 21.7278C21.3524 22.5651 21.5901 23.3843 22.0326 24.0959C22.475 24.8075 23.1051 25.384 23.8539 25.7625C22.3492 26.1953 21.0257 27.1027 20.0816 28.3488C19.1375 29.595 18.6237 31.1128 18.6172 32.6747V36.0838H33.1671V32.6747C33.1586 31.1087 32.6403 29.5879 31.6902 28.3412C30.7401 27.0946 29.4095 26.1896 27.899 25.7625Z" fill="white" />
                        </svg>
                      </div>
                      <div className='w-75 px-3'>
                        <span className=''>Username</span>
                      </div>
                    </div>
                    <div className='mockup-input-container d-flex align-items-center mt-3'>
                      <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
                          <path d="M46.92 0.421875H5.08594C2.32451 0.421875 0.0859375 2.66045 0.0859375 5.42187V47.1223C0.0859375 49.8837 2.32451 52.1223 5.08593 52.1223H46.92C49.6814 52.1223 51.92 49.8837 51.92 47.1223V5.42188C51.92 2.66045 49.6814 0.421875 46.92 0.421875Z" fill="#FC0226" />
                          <path d="M31.7896 21.5957H30.5039V20.0319C30.5039 18.63 29.9456 17.2855 28.9518 16.2943C27.9579 15.303 26.61 14.7461 25.2045 14.7461C23.799 14.7461 22.4511 15.303 21.4572 16.2943C20.4634 17.2855 19.9051 18.63 19.9051 20.0319V21.5957H18.6508C18.1684 21.5957 17.7058 21.7868 17.3647 22.127C17.0236 22.4672 16.832 22.9286 16.832 23.4098V33.6998C16.832 34.1809 17.0236 34.6423 17.3647 34.9825C17.7058 35.3227 18.1684 35.5138 18.6508 35.5138H31.7896C32.272 35.5138 32.7346 35.3227 33.0756 34.9825C33.4167 34.6423 33.6083 34.1809 33.6083 33.6998V23.4098C33.6083 22.9286 33.4167 22.4672 33.0756 22.127C32.7346 21.7868 32.272 21.5957 31.7896 21.5957ZM22.3823 20.0319C22.34 19.6267 22.3835 19.2171 22.51 18.8298C22.6365 18.4424 22.8432 18.0859 23.1167 17.7833C23.3901 17.4807 23.7242 17.2387 24.0974 17.0731C24.4706 16.9075 24.8745 16.8219 25.2829 16.8219C25.6913 16.8219 26.0952 16.9075 26.4684 17.0731C26.8416 17.2387 27.1757 17.4807 27.4491 17.7833C27.7226 18.0859 27.9293 18.4424 28.0558 18.8298C28.1823 19.2171 28.2258 19.6267 28.1835 20.0319V21.5957H22.3196L22.3823 20.0319ZM26.2707 30.7598V33.387C26.2631 33.4395 26.2366 33.4875 26.1963 33.522C26.1559 33.5565 26.1043 33.5752 26.0512 33.5747H24.7969C24.747 33.5747 24.6991 33.5549 24.6638 33.5197C24.6285 33.4845 24.6087 33.4368 24.6087 33.387V30.7598C24.1025 30.5661 23.6803 30.2019 23.4153 29.7301C23.1503 29.2584 23.0593 28.709 23.158 28.1773C23.2567 27.6457 23.539 27.1652 23.9558 26.8194C24.3726 26.4736 24.8976 26.2843 25.4397 26.2843C25.9818 26.2843 26.5068 26.4736 26.9236 26.8194C27.3404 27.1652 27.6226 27.6457 27.7213 28.1773C27.8201 28.709 27.729 29.2584 27.464 29.7301C27.1991 30.2019 26.7768 30.5661 26.2707 30.7598Z" fill="white" />
                        </svg>
                      </div>
                      <div className='w-75 px-3'>
                        <span className=''>Password</span>
                      </div>
                    </div>

                    <Row className='px-0 mx-0 pt-4'>
                      <Col lg={6} md={6} sm={6} xs={6} className='pl-0'>
                        <div className='d-flex align-items-center'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path opacity="0.5" d="M16.3016 0.621094H0.121094V16.7599H16.3016V0.621094Z" fill="black" />
                            <path d="M5.67349 13.5673L1.78516 9.68904L3.47846 8.03135L5.67349 10.2207L12.5722 3.33984L14.2341 4.99753L5.67349 13.5673Z" fill="#B3B3B3" />
                          </svg>
                          <span className='ml-2 forgotPassword'>Remember Me?</span>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={6} className='text-right pr-0'>
                        <span className='forgotPassword'>Forgot Password?</span>
                      </Col>
                    </Row>
                    <div className='mockup-btn'>
                      <div className='mockup-login-btn text-center px-3 py-3 '>
                        <span className='redBtnText'>LOGIN</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={7} md={6} xs={12} sm={12} className='px-0'>
                  <div className='px-md-5 py-md-0 pt-5 mt-3 mt-md-0'>
                    <div className='reveal fade-right'>
                      <span className='section-title'>Deposits & withdrawals.</span>
                    </div>
                    <div className='mt-2 reveal fade-right'>
                      <span className='noDepositFee text-red'>No Deposit Fees. Fast Withdrawals.</span>
                    </div>
                    <div className='mt-5 reveal fade-right'>
                      <span className='section-description'>We offer a range of banking options for simple, secure and fast deposits and withdrawals. Login to your Members Area for full details.</span>
                    </div>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Deposit