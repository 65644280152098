import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import React, { useState } from 'react';

const list = [
    { key: '1', title: 'When can I trade Indices?', description: 'Each Index runs on its own country’s time, for instance the UK 100 (FTSE) runs on GMT time and as such is open to trade during the day based on GMT. Therefore, each Index has differing trading hours. For a full breakdown, visit our Indices market page.' },
    { key: '2', title: 'What are the major Indices I can trade with Bost Forex?', description: "At Bost, you can trade all the major Indices, including the Dow Jones, Nasdaq and UK 100. For a full list of the Indices that you can trade, visit our Indices market page." },
    { key: '3', title: 'Are there any hidden fees when trading Indices?', description: 'There are no hidden fees when trading Indices with Bost.' },
    { key: '4', title: 'What fees in general are there when trading Indices with Bost Forex?', description: 'With certain markets, you may be subject to overnight fees but we ensure all our costs remain highly competitive within the industry.' },
    { key: '5', title: 'What is the difference between Index trading and stock trading?', description: 'Indices are comprised of a number of stocks, be it 30, 100 or even 250. By trading Indices, you’re speculating on the combined performance of all the stocks within an Index. Stock trading, on the other hand, involves trading just one stock.' },
]

function IndicesTrading(props) {
    const [selectedItem, setSelectedItem] = useState(['1']);

    const openAccordion = (id) => {
        let newArray = selectedItem;
        if (newArray.includes(id)) {
            newArray = newArray.filter(item => item !== id);
            setSelectedItem(newArray);
        } else {
            newArray = newArray.concat(id);
            setSelectedItem(newArray);
        }
    }
    return (
        <>
            <div className='py-5 indicestrading'>
                <div className='py-lg-5'>
                    <div className='reveal fade-left d-block d-md-none mb-4'>
                        <img src={require('../../../assets/img/market/indicestrading.png')} className='img-fluid' alt="forex trading" />
                    </div>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            {/* <Col lg={12} md={12} xs={12} sm={12} className='px-0 mb-4 text-center text-md-right mb-md-0 d-block d-md-none'>
                                <div className='reveal fade-left'>
                                    <img src={require('../../../assets/img/market/indicestrading.png')} className='img-fluid' alt="forex trading" />
                                </div>
                            </Col> */}
                            <Col lg={11} md={11} xs={12} sm={12} className='px-0'>
                                <div className='text-left text-md-center reveal fade-left'>
                                    <span className='section-title'><span className="text-red">Indices Trading</span></span>
                                </div>
                                <div className='text-left text-md-center reveal fade-left my-4'>
                                    <span className='section-description'>The major global indices such as the Dow Jones and the FTSE 100 are comprised of some of the largest companies in the world. The weighting of the Indices can often accurately reflect the economic performance of a country. Our tight spreads, mobile and desktop trading capabilities and 24/5-access to the markets keeps you connected, so you can trade Indices anytime, anywhere on MT5. Join the thousands already a part of the Bost community, and let us help you master the MT5 platform, analyse the markets and identify Indices trading opportunities.</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-start px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 pl-md-3 mt-md-5 mt-4 mx-0 order-2 order-lg-1'>
                                        <div className='reveal fade-bottom'>
                                            <Accordion allowMultipleExpanded={true} className="text-left">
                                                {list.map((item, i) => (
                                                    <AccordionItem key={i} className={`border-none accordion-item ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>
                                                        <AccordionItemHeading className='px-0'>
                                                            <AccordionItemButton className={`px-0`}>
                                                                <Row className={`mx-0 px-0 justify-content-center align-items-center ${selectedItem.includes(item.key) ? i !== 0 ? 'pt-3' : 'pt-0' : 'py-3'}`} onClick={() => openAccordion(item.key)}>
                                                                    <Col lg={10} md={10} sm={11} xs={11} className="px-0">
                                                                        <div>
                                                                            <span className={`faq-title ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>Q{item.key}. {item.title}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                                                        <div className="text-end">
                                                                            {
                                                                                selectedItem.includes(item.key) ? (<img src={require('../../../assets/img/common/minus.svg').default} className='expand-arrow' alt="up" />) : <img src={require('../../../assets/img/common/plus.svg').default} className='expand-arrow' alt="down" />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={`px-0 ${selectedItem.includes(item.key) ? "d-block" : "d-none"}`}>
                                                            <div className="accordionContent px-0">
                                                                <Row className="mx-0 px-0 align-items-center">
                                                                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                                        <div className={`${selectedItem.includes(item.key) ? 'py-3' : 'py-3'} `}>
                                                                            <span className='faq-description'>{item.description}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}

                                            </Accordion>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pr-md-3 mt-5 text-center text-md-right order-1 order-lg-2 mb-md-0 d-none d-md-block'>
                                        <div className='reveal fade-left'>
                                            <img src={require('../../../assets/img/market/indicestrading.png')} className='img-fluid' alt="forex trading" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(IndicesTrading);