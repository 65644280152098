import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useDraggable } from "react-use-draggable-scroll";
import { Col, Container, Row } from 'reactstrap';
import "../../../assets/css/home/reason.scss";
import { translate } from 'views/utils/translate';
import { connect } from 'react-redux';

const list = [
  { name: 'Leverage 1:1000', description: 'Gain greater exposure to the markets with our high leverage of up to 1:1000.', icon: require('../../../assets/img/home/leverage.svg').default, activeIcon: require('../../../assets/img/home/leverage-active.svg').default },
  { name: '0.005 Execution Speed', description: 'Nullify the impact of slippage and get exact prices with our instant execution on all trades.', icon: require('../../../assets/img/home/execution.svg').default, activeIcon: require('../../../assets/img/home/execution-active.svg').default },
  { name: 'Spreads from 0.5 Pips', description: 'Starting from 0.5 pips, trade with some of the tightest spreads in the industry.', icon: require('../../../assets/img/home/spread.svg').default, activeIcon: require('../../../assets/img/home/spread-active.svg').default },
  { name: 'No hidden fees', description: 'Enjoy trading all the most popular, global markets without any hidden fees.', icon: require('../../../assets/img/home/fees.svg').default, activeIcon: require('../../../assets/img/home/fees-active.svg').default },
]

function WhyTradeBostForex(props) {
  const ref = useRef();
  const itemPerPage = 4;
  // const { events } = useDraggable(ref);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(Math.ceil(list / itemPerPage));
  const [data, setData] = useState([]);

  useEffect(() => {
    setPageNumber(1);
    let total = Math.ceil(list.length / itemPerPage);
    setPageCount(total);
  }, [])

  useEffect(() => {
    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const pageItems = list.slice(startIndex, endIndex);
    setData(pageItems);
  }, [pageNumber])

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => nextSlide(),
  //   onSwipedRight: () => prevSlide(),
  //   swipeDuration: 500,
  //   preventScrollOnSwipe: true,
  //   trackMouse: true
  // });

  const nextSlide = () => {
    if (pageNumber !== pageCount) {
      setPageNumber(pageNumber + 1);
    } else if (pageNumber === pageCount) {
      setPageNumber(1);
    }
  }

  const prevSlide = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(pageCount);
    }
  }

  return (
    <>
      <div className='whytradebost'>
        <Container className='py-5 position-relative h-100'>
          <Row className='mx-0 px-0 align-items-center h-100'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              <Row className='mx-0 px-0 align-items-lg-center align-items-center h-100'>
                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-0 mb-lg-5 highresscreen'>
                  <div className='reveal fade-left'>
                    <span className='section-title'>Why trade with <span className='text-red'>bost forex?</span></span>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className='mt-md-5 pt-5 px-0'>
                  <Row className='px-0 mx-0'>
                    {list.map((item, i) => (
                      <Col lg={3} md={3} sm={12} xs={12} key={i} className='slider-box reveal flip mb-4 px-0 px-md-2'>
                        <div className='w-100'>
                          <div className='mb-lg-4'>
                            <img src={item.icon} className='icon inactive' alt={item.name} />
                            <img src={item.activeIcon} className='icon active' alt={item.name} />
                          </div>
                          <div className='greyDivider my-3' />
                          <div>
                            <span className='slider-name text-uppercase'>{translate(props.lang, item.name)}</span>
                          </div>
                          <div className='mt-3'>
                            <span className='slider-description lh-base'>{translate(props.lang, item.description)}</span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {/* <Col lg={12} md={12} sm={12} xs={12} className='mt-md-5 pt-5 px-0'> */}
                  {/* <div className='d-none d-lg-block'>
                    <div
                      className="d-flex justify-content-between overflow-x-scroll scrollbar-hide pt-5"
                      {...events}
                      ref={ref} 
                    >
                      {list.map((item, i) => (
                        <div key={i} className='slider-box mr-3 reveal flip'>
                          <div className='w-100'>
                            <div className='mb-lg-4'>
                              <img src={item.icon} className='icon inactive' alt={item.name} />
                              <img src={item.activeIcon} className='icon active' alt={item.name} />
                            </div>
                            <div className='greyDivider my-3' />
                            <div>
                              <span className='slider-name text-uppercase'>{translate(props.lang, item.name)}</span>
                            </div>
                            <div className='mt-3'>
                              <span className='slider-description lh-base'>{translate(props.lang, item.description)}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                  {/* <div className='d-block d-lg-none position-relative'  {...handlers}>
                    <div className='slider-mobile-container whytradebostforex'>
                      <Row className='justify-content-center align-items-start px-0 mx-0'>
                        {data.map((item, i) => (
                          <Col sm={6} xs={6} key={i} className={`h-100 mb-4 ${i % 2 === 0 ? 'pl-0' : 'pr-0'}`}>
                            <div key={i} className='reveal flip slider-mobile-box whytradebostforex'>
                              <div className='w-100'>
                                <div className='mb-lg-4'>
                                  <img src={item.icon} className='icon inactive' alt={item.name} />
                                  <img src={item.activeIcon} className='icon active' alt={item.name} />
                                </div>
                                <div className='greyDivider my-3' />
                                <div className='d-flex align-items-start name-container'>
                                  <span className='slider-name lh-sm text-uppercase'>{translate(props.lang,item.name)}</span>
                                </div>
                                <div>
                                  <span className='slider-description lh-base'>{translate(props.lang, item.description)}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div> */}
                {/* </Col> */}
                <Col lg={12} md={12} sm={12} xs={12} className='pt-lg-5 mt-lg-5 pt-2 px-0'>
                  <span className='startTradeText reveal fade-left'>{translate(props.lang, "Choose your account type and start trading today!")} <a href="/contact-us" className='text-red text-underline cursor-pointer'>{translate(props.lang, "Create Account")}</a> {translate(props.lang, "or")} <a href="/contact-us" className='text-yellow text-underline cursor-pointer'>{translate(props.lang, "register for free demo.")}</a></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}


const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(WhyTradeBostForex);