import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useDraggable } from "react-use-draggable-scroll";
import { Col, Container, Row } from 'reactstrap';
import "../../../assets/css/home/reason.scss";

const list = [
  { name: 'FAST TECHNOLOGY', description: 'Our unique technology gives you seamless execution', icon: require('../../../assets/img/market/technology.svg').default, activeIcon: require('../../../assets/img/market/technology-active.svg').default },
  { name: '100% EXECUTION RATE', description: 'We are proud to offer a 100% execution rate', icon: require('../../../assets/img/market/execution.svg').default, activeIcon: require('../../../assets/img/market/execution-active.svg').default },
  { name: 'REAL-TIME', description: 'Real-time market execution at your finger tips', icon: require('../../../assets/img/market/time.svg').default, activeIcon: require('../../../assets/img/market/time-active.svg').default },
  { name: 'NO RE-QUOTES', description: 'No re-quotes / no rejections policy', icon: require('../../../assets/img/market/quotes.svg').default, activeIcon: require('../../../assets/img/market/quotes-active.svg').default },
]

function BestExecution() {
  const ref = useRef();
  const itemPerPage = 4;
  // const { events } = useDraggable(ref);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(Math.ceil(list / itemPerPage));
  const [data, setData] = useState([]);

  useEffect(() => {
    setPageNumber(1);
    let total = Math.ceil(list.length / itemPerPage);
    setPageCount(total);
  }, [])

  useEffect(() => {
    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const pageItems = list.slice(startIndex, endIndex);
    setData(pageItems);
  }, [pageNumber])

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const nextSlide = () => {
    if (pageNumber !== pageCount) {
      setPageNumber(pageNumber + 1);
    } else if (pageNumber === pageCount) {
      setPageNumber(1);
    }
  }

  const prevSlide = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(pageCount);
    }
  }

  return (
    <>
      <div className='execution d-flex align-items-center'>
        <Container className='py-5 position-relative h-100'>
          <Row className='mx-0 px-0 align-items-center h-100'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              <Row className='mx-0 px-0 align-items-lg-center align-items-center h-100'>
                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-0 mb-lg-5 highresscreen'>
                  <div className='reveal fade-left'>
                    <span className='section-title'>We always give you the <span className='text-red'>best execution.</span></span>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className='mt-md-5 pt-5 px-0'>
                  <Row className='px-0 mx-0'>
                    {list.map((item, i) => (
                      <Col lg={3} md={3} sm={12} xs={12} key={i} className='slider-box reveal flip mb-md-0 mb-4 px-0 px-md-2'>
                        <div className='w-100'>
                          <div className='mb-lg-4'>
                            <img src={item.icon} className='icon inactive' alt={item.name} />
                            <img src={item.activeIcon} className='icon active' alt={item.name} />
                          </div>
                          <div className='greyDivider my-3' />
                          <div>
                            <span className='slider-name'>{item.name}</span>
                          </div>
                          <div className='mt-3'>
                            <span className='slider-description lh-base'>{item.description}</span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  {/* <div className='d-none d-lg-block'>
                    <div
                      className="d-flex justify-content-between overflow-x-scroll scrollbar-hide pt-5"
                      {...events}
                      ref={ref} // add reference and events to the wrapping div
                    >
                      {list.map((item, i) => (
                        <div key={i} className='slider-box mr-3 reveal flip'>
                          <div className='w-100'>
                            <div className='mb-lg-4'>
                              <img src={item.icon} className='icon inactive' alt={item.name} />
                              <img src={item.activeIcon} className='icon active' alt={item.name} />
                            </div>
                            <div className='greyDivider my-3' />
                            <div>
                              <span className='slider-name'>{item.name}</span>
                            </div>
                            <div className='mt-3'>
                              <span className='slider-description lh-base'>{item.description}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='d-block d-lg-none position-relative'  {...handlers}>
                    <div className='slider-mobile-container'>
                      <Row className='justify-content-center align-items-start px-0 mx-0'>
                        {data.map((item, i) => (
                          <Col sm={6} xs={6} key={i} className={`h-100 mb-4 ${i % 2 === 0 ? 'pl-0' : 'pr-0'}`}>
                            <div key={i} className='reveal flip slider-mobile-box'>
                              <div className='w-100'>
                                <div className='mb-lg-4'>
                                  <img src={item.icon} className='icon inactive' alt={item.name} />
                                  <img src={item.activeIcon} className='icon active' alt={item.name} />
                                </div>
                                <div className='greyDivider my-3' />
                                <div className='d-flex align-items-start name-container'>
                                  <span className='slider-name lh-sm'>{item.name}</span>
                                </div>
                                <div>
                                  <span className='slider-description lh-base'>{item.description}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default BestExecution