import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import menu from "views/utils/menu2";
import "../../../assets/css/common/mainnavbar.scss";
import Drawer from './Drawer';
import { translate } from 'views/utils/translate';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        height: 'auto',
        backgroundColor: '#05010E',
        borderColor: 'transparent',
        borderRadius: '5px',
    },
    overlay: {
        backgroundColor: "rgba(255,255,255,0.1)",
        zIndex: '99999'
    }
};

const language = [
    { name: 'EN', value: 'EN', icon: require('../../../assets/img/language/en.png') },
    // { name: 'FR', value: 'FR', icon: require('../../../assets/img/language/fr.png') },
    // { name: 'ES', value: 'ES', icon: require('../../../assets/img/language/es.png') },
]

function HeaderNavBar(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [showBurger, setShowBurger] = useState(false);
    const [navbarLogo, setNavbarLogo] = useState("logo-red");
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [navbarColor, setNavbarColor] = useState("");
    const [path, setPath] = useState('/');
    const [childMenu, setChildMenu] = useState('');
    const [checked, setChecked] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setPath(location.pathname);
        if (window.innerWidth <= 991) {
            setShowBurger(true);
        }
    }, [location]);

    useEffect(() => {
        const updateNavbarColor = () => {
            if (document.documentElement.scrollTop > 39 || document.body.scrollTop > 39) {
                setNavbarColor("");
                setNavbarLogo("logo-red");
                // setShowBurger(true);
            } else if (document.documentElement.scrollTop < 40 || document.body.scrollTop < 40) {
                // setNavbarColor("navbar-transparent");
                // setNavbarLogo("logo-white");
                setNavbarLogo("logo-red");
                setNavbarColor("");
                
                if (window.innerWidth <= 991) {
                    setShowBurger(true);
                } else {
                    setShowBurger(false);
                }
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });


    const changeLanguage = (val) => {
        dispatch({
            type: 'UPDATE_LANGUAGE',
            lang: val
        });
        localStorage.setItem('sp-lang', val);
    }

    const selectMenuMobile = (parent) => {
        if (parent === selectedMenu) {
            setSelectedMenu('');
            setChildMenu('');
        } else {
            setSelectedMenu(parent);
            setChildMenu('');
        }
    }

    const selectChildMenuMobile = (child) => {
        if (child === childMenu) {
            setChildMenu('');
        } else {
            setChildMenu(child);
        }
    }

    const handleChange = (e) => {
        setChecked(e.target.checked)
        document.querySelector("body").classList.toggle("menu-toggle");
        if (!e.target.checked) {
            setSelectedMenu('');
        }
    }

    const handleOptionSelect = async (e) => {
        const { value } = e;
        changeLanguage(value)
        dispatch({
            type: 'UPDATE_LANGUAGE',
            lang: value
        });
    }

    const goToHome = () => {
        window.location.href = "/"
    }

    const getOptionLabel = (option) => (
        <div className='d-flex align-items-center'>
            <img src={option.icon} className="lang-icon" alt="flag" />
            <span className="ml-3 menu-text">{option.label}</span>
        </div>
    );

    const selectLang = (val) => {
        changeLanguage(val);
        closeModal();
        dispatch({
            type: 'UPDATE_LANGUAGE',
            lang: val
        });
    }

    const openModal = () => {
        setIsOpen(!modalIsOpen);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className={`main-navbar ${navbarColor} ${navbarLogo} fixed-top ${props.theme === 'transparent-theme' ? props.theme : 'red-theme'}`} expand="lg" color={"white"}>
                <Container className="px-0">
                    <div className="navbar-translate justify-content-between w-100 d-flex align-items-center">

                        <NavbarBrand>
                            {checked ? (
                                <img src={require('../../../assets/img/logo/logo-word.png')} className="header-logo" alt="logo" onClick={() => goToHome()} />
                            ) : (
                                <>
                                    {props.theme === 'transparent-theme' ? (
                                        <img src={require('../../../assets/img/logo/logo-word-red.png')} className="header-logo white" alt="logo" onClick={() => goToHome()} />
                                    ) : (
                                        <img src={require('../../../assets/img/logo/logo-word.png')} className="header-logo white" alt="logo" onClick={() => goToHome()} />
                                    )}
                                    <img src={require('../../../assets/img/logo/logo-word-red.png')} className="header-logo red" alt="logo" onClick={() => goToHome()} />
                                </>
                            )}
                        </NavbarBrand>
                        <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-none d-lg-block'>
                                <Nav navbar className="mr-3">
                                    {menu.map((item, i) => (
                                        <NavItem key={i}>
                                            <NavLink href={item.pathName} >
                                                <span className={`menu-text ${path === item.pathName ? 'active': ''}`}>{translate(props.lang,item.menu)}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            <UncontrolledDropdown nav className={`d-none d-lg-block language-menu ${checked ? 'active' : 'inactive'}`}>
                                <DropdownToggle
                                    color="default"
                                    href="#pablo"
                                    nav
                                    className='px-0'
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {language.filter(item => item.value.toLowerCase() === props.lang.toLowerCase()).map((item, i) => (
                                        <div className="d-flex align-items-center" key={i}>
                                            <img src={item.icon} className="lang-icon" alt={item.name} />
                                            <span className="px-2 lang-menu-text">{item.name}</span>
                                            <div className='lang-arrow'>
                                                {props.theme === 'transparent-theme' ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.88068 0.816411L3.28807 3.85993C3.12897 4.04669 2.87103 4.04669 2.71193 3.85993L0.119322 0.816411C-0.039774 0.629645 -0.039774 0.326839 0.119322 0.140074C0.278418 -0.0466916 0.536362 -0.0466916 0.695457 0.140074L2.90766 2.73702C2.93733 2.72895 2.96824 2.72469 3 2.72469C3.03176 2.72469 3.06267 2.72895 3.09234 2.73702L5.30454 0.140074C5.46364 -0.0466914 5.72158 -0.0466914 5.88068 0.140074C6.03977 0.326839 6.03977 0.629646 5.88068 0.816411Z" fill={`${navbarColor === 'navbar-transparent' ? '#555555' : checked ? '#555555' : '#000'}`} />
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.88068 0.816411L3.28807 3.85993C3.12897 4.04669 2.87103 4.04669 2.71193 3.85993L0.119322 0.816411C-0.039774 0.629645 -0.039774 0.326839 0.119322 0.140074C0.278418 -0.0466916 0.536362 -0.0466916 0.695457 0.140074L2.90766 2.73702C2.93733 2.72895 2.96824 2.72469 3 2.72469C3.03176 2.72469 3.06267 2.72895 3.09234 2.73702L5.30454 0.140074C5.46364 -0.0466914 5.72158 -0.0466914 5.88068 0.140074C6.03977 0.326839 6.03977 0.629646 5.88068 0.816411Z" fill={`${navbarColor === 'navbar-transparent' ? '#fff' : checked ? '#fff' : '#000'}`} />
                                                    </svg>
                                                )}

                                            </div>
                                        </div>
                                    ))}
                                </DropdownToggle>
                                <DropdownMenu className='mx-0'>
                                    {language.map((item, i) => (
                                        <DropdownItem key={i} onClick={() => changeLanguage(item.value)} className="w-100 d-flex align-items-center justify-content-center px-4 mx-0">
                                            <img src={item.icon} className="lang-icon" alt={item.name} />
                                            <span className="pl-2 menu-text">{item.name}</span>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <div className={`hamburger-ui ${showBurger ? 'active' : 'inactive'}`}>
                                <div className={`d-flex hamburger-container ml-3`}>
                                    <input id="hamburger" className="hamburger" type="checkbox" checked={checked} onChange={handleChange} />
                                    <label className={`hamburger mb-0`} htmlFor="hamburger">
                                        <i className={`${path === '/' ? "homeHamburger" : ""} ${checked ? 'checked' : 'unchecked'}`}></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth >= 768 ?
                        <div className="align-items-center justify-content-center d-none d-md-block">
                            <div className="d-flex justify-content-center align-items-center hamburger-container">
                                <div>
                                    <section className={`drawer-list ${checked ? 'active' : 'inactive'}`}>
                                        <Drawer />
                                    </section>
                                </div>
                            </div>
                        </div>
                        : null}

                </Container>
                {/* <Container className={`px-0 mt-2 secondRowNavbar ${checked ? 'd-none' : ''}`}>
                    <Nav navbar className="">
                        {menu.map((item, i) => (
                            item.children.length > 0 ? (
                                <UncontrolledDropdown nav key={i}>
                                    <DropdownToggle
                                        color="default"
                                        nav
                                        className="px-0 mr-4 d-flex align-items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <>
                                            <span className={`pr-2 menu-text  ${item.children.filter(item => item.pathName === location.pathname).length > 0 ? 'active' : ''}`}>{item.menu}</span>
                                            <div className='arrow-menu'>
                                                {props.theme === 'transparent-theme' ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.88068 0.816411L3.28807 3.85993C3.12897 4.04669 2.87103 4.04669 2.71193 3.85993L0.119322 0.816411C-0.039774 0.629645 -0.039774 0.326839 0.119322 0.140074C0.278418 -0.0466916 0.536362 -0.0466916 0.695457 0.140074L2.90766 2.73702C2.93733 2.72895 2.96824 2.72469 3 2.72469C3.03176 2.72469 3.06267 2.72895 3.09234 2.73702L5.30454 0.140074C5.46364 -0.0466914 5.72158 -0.0466914 5.88068 0.140074C6.03977 0.326839 6.03977 0.629646 5.88068 0.816411Z" fill="#555555" />
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.88068 0.816411L3.28807 3.85993C3.12897 4.04669 2.87103 4.04669 2.71193 3.85993L0.119322 0.816411C-0.039774 0.629645 -0.039774 0.326839 0.119322 0.140074C0.278418 -0.0466916 0.536362 -0.0466916 0.695457 0.140074L2.90766 2.73702C2.93733 2.72895 2.96824 2.72469 3 2.72469C3.03176 2.72469 3.06267 2.72895 3.09234 2.73702L5.30454 0.140074C5.46364 -0.0466914 5.72158 -0.0466914 5.88068 0.140074C6.03977 0.326839 6.03977 0.629646 5.88068 0.816411Z" fill="white" />
                                                    </svg>
                                                )}
                                            </div>
                                        </>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {item.children.map((childItem, j) => (
                                            <DropdownItem to={childItem.pathName} key={j} tag={Link}>
                                                <span className={`menu-text ${location.pathname === childItem.pathName ? 'active' : ''}`}>{childItem.menu}</span>
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            ) : (
                                <NavItem>
                                    <NavLink
                                        href={item.pathName}
                                    >
                                        <span className="menu-text">{item.menu}</span>
                                    </NavLink>
                                </NavItem>
                            )
                        ))}
                    </Nav>
                </Container> */}
                {window.innerWidth >= 768 ? null :
                    <div className="align-items-center justify-content-center d-block d-md-none">
                        <div className="d-flex justify-content-center align-items-center hamburger-container">
                            <div>
                                <section className={`drawer-list ${checked ? 'active' : 'inactive'}`}>
                                    <div className='d-flex w-100 justify-content-center h-100'>
                                        <Container className='px-0 position-relative h-100'>
                                            <Row className="mt-4 px-0 mx-0">
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <ul className="mobile-menu-list">
                                                        {menu.map((item, i) => (
                                                            <li className={`dropdown-mobile w-100`} key={i}>
                                                                {/* <a onClick={() => { selectMenuMobile(item.menu); selectChildMenuMobile(''); }} className={`px-0 ${selectedMenu === item.menu ? "active" : ""}`}> */}
                                                                <a href={item.pathName} className={`px-0 ${selectedMenu === item.menu ? "active" : ""}`}>
                                                                    <div className='d-flex w-100 align-items-center justify-content-start'>
                                                                        <div>
                                                                            <span className={`mobile-parent-menu-text text-capitalize ${item.children.filter(item => item.pathName === location.pathname).length > 0 ? 'active' : ''}`}>{translate(props.lang, item.menu)} </span>
                                                                        </div>
                                                                        {item.children.length > 0 ? (
                                                                            <div className='ml-3'>
                                                                                <img src={require('../../../assets/img/common/white-arrow-down.svg').default} alt="arrow" />
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </a>
                                                                {item.children.length > 0 ? (
                                                                    <ul className={`dropdown-mobile-menu dropdown-mobile-intial level-1 list-1 px-1 ${selectedMenu === item.menu ? "" : 'panel-collapse collapse'}`}>
                                                                        {item.children.map((childItem, j) => (
                                                                            <li className='text-left' key={j}>
                                                                                <a href={childItem.pathName} className={`mobile-child-menu-text text-left text-capitalize py-1  ${location.pathname === childItem.pathName ? 'active' : ''}`}>{childItem.menu}</a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : null}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Col>
                                            </Row>

                                            <div className='mobile-language w-100'>
                                                <ul>
                                                    <li className="h-100">
                                                        {/* <Select
                                                            options={language.map((item, i) => ({ value: item.value, label: item.name, key: i, icon: item.icon }))}
                                                            className="input-transparent w-100 text-capitalize px-0 select select-lang"
                                                            styles={customLangSelectStyles}
                                                            menuPlacement='top'
                                                            name="language"
                                                            value={props.lang ? language.filter(item => item.value.toLowerCase() === props.lang.toLowerCase()).map((item, i) => ({ value: item.value, label: item.name, key: i, icon: item.icon })) : ''}
                                                            onChange={(e) => handleOptionSelect(e)}
                                                            getOptionLabel={getOptionLabel}
                                                        /> */}
                                                        <div>
                                                            <Row className='px-0 mx-0'>
                                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                                    {language.filter(item => item.value.toLowerCase() === props.lang.toLowerCase()).map((item, i) => (
                                                                        <div className='d-flex align-items-center w-100' key={i} onClick={() => openModal()}>
                                                                            <img src={item.icon} className="lang-icon" alt="flag" />
                                                                            <span className="ml-3 menu-text">{item.name}</span>
                                                                        </div>
                                                                    ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    {/* <li className='mt-2'>
                                                        <Row className='align-items-center justify-content-center px-0 mx-0'>
                                                            <Col lg={8} md={12} sm={12} xs={12} className='px-0 mb-lg-0'>
                                                                <img src={require('../../../assets/img/headerBackground/redDivider.svg').default} alt="divider" className='img-fluid' />
                                                            </Col>
                                                            <Col lg={4} md={6} sm={12} xs={12} className='px-0'>
                                                                <div className="d-flex align-items-center justify-content-between w-100">
                                                                    {socialLink.map((item, i) => (
                                                                        <a key={i} href={item.url} className="social-icon px-2">
                                                                            <img src={item.icon} alt={item.name} className="img-fluid" />
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </Container>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="language-options country-options">
                        {language.map((item, i) => (
                            <div className={`d-flex align-items-center w-100 ${i !== language.length - 1 ? 'mb-3' : ''}`} key={i} onClick={() => selectLang(item.value)}>
                                <img src={item.icon} className="lang-icon" alt="flag" />
                                <span className="ml-3 menu-text text-white">{item.name}</span>
                            </div>
                        ))}
                    </div>
                </Modal>
            </Navbar>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    }
}
export default connect(mapStateToProps)(HeaderNavBar);