import { useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import "../../../assets/css/home/home.scss";
import Footer from '../Component/Footer';
import HeaderNavBar from '../Component/HeaderNavBar';
import TradeRiskFree from '../Component/TradeRiskFree';
import TradingMarquee from '../Component/TradingMarquee';
import ContactForm from '../ContactUs/ContactForm';
import Connecting from './Connecting';
import Experience from './Experience';
import Header from './Header';
import RightAccount from './RightAccount';
import WhyTradeBostForex from './WhyTradeBostForex';
import PopularMarket from './PopularMarket';

function Home(props) {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [history])
  return (
    <>
      <HeaderNavBar />
      <div className='contents'>
      <div className="home">
        <Header />
        <Connecting />
        <WhyTradeBostForex />
        <RightAccount />
        <PopularMarket />
        <Experience />
        <div className='pb-5 contactus contactusHome mt-md-5'>
          <ContactForm />
        </div>
        <TradeRiskFree />
      </div>
      <Footer />
      <TradingMarquee />
      <ScrollToTop smooth className="scrolltotop d-flex align-items-center justify-content-center" component={<div><FaArrowUp color="#fff" /></div>} />
      </div>
    </>
  )
}
const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(Home);