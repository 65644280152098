import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import "../../../assets/css/home/rightaccount.scss";
import { translate } from 'views/utils/translate';

function RightAccount(props) {
    return (
        <>
            <div className='rightaccount d-flex align-items-center position-relative'>
                <Container className='py-5 h-100'>
                    <Row className='mx-0 px-0 align-items-center h-100 py-md-5'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='mx-0 px-0 align-items-lg-center align-items-center justify-content-start h-100'>
                                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-0 mb-lg-5 highresscreen'>
                                    <div className='reveal fade-left text-start'>
                                        <span className='section-title'>{translate(props.lang, "Find the right account for you.")}</span>
                                    </div>
                                </Col>
                                <Col lg={10} md={10} sm={12} xs={12} className='mt-3 px-0'>
                                    <div className='text-start reveal fade-right'>
                                        <span className='section-description d-block'>{translate(props.lang, "Trade over 50 markets on MT5 now with our Standard account and experience all the thrills of live trading.Not ready to trade for real?")}</span>
                                        <span className='section-description'>{translate(props.lang, "Try it out with our free Demo account.")}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className='px-0 mx-0 my-4'>
                                    <Row className="px-0 mx-0 justify-content-between py-2 py-md-5 rightaccount-box-container">
                                        <Col lg={6} md={6} sm={12} xs={12} className={`mb-3 mb-md-0 position-relative px-0`}>
                                            <div className={`rightaccount-box accountOne p-5 mx-md-2 mx-0 reveal fade-bottom`}>
                                                <div className='my-3 px-lg-5'>
                                                    <span className='name'>Standard</span>
                                                </div>
                                                <div className='px-lg-5'>
                                                    <span className='amount'>$200</span>
                                                </div>
                                                <div className='my-4 px-lg-5'>
                                                    <span className='minDeposit'>Min. Deposit</span>
                                                </div>
                                                <div className='mb-4 px-lg-5'>
                                                    <ul className='mx-0'>
                                                        <li className='mb-2 description'>Leverage <b>Up to 1:1000</b></li>
                                                        <li className='mb-2 description'><b>All</b> Market Available</li>
                                                        <li className='mb-2 description'><b>MT5</b> Platform</li>
                                                    </ul>
                                                </div>
                                                <div className='mx-lg-5'>
                                                    <Row className='px-0 mx-0'>
                                                        <Col lg={10} md={10} sm={12} xs={12} className='px-0'>
                                                            <Link to="/contact-us" className="text-decoration-none">
                                                                <div className='text-center yellowBtn px-1 py-3 h-100'>
                                                                    <span className='yellowBtnText'>Create Account</span>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='active-left-corner'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                                                        <rect x="35" y="70" width="35" height="35" transform="rotate(180 35 70)" fill="#F9C258" />
                                                        <rect opacity="0.8" x="35" y="35" width="35" height="35" transform="rotate(180 35 35)" fill="#E6E6F3" />
                                                        <rect x="70" y="35" width="35" height="35" transform="rotate(180 70 35)" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className='inactive-left-corner'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                                                        <rect y="35" width="35" height="35" transform="rotate(-90 0 35)" fill="#FC0226" />
                                                        <rect opacity="0.8" x="35" y="35" width="35" height="35" transform="rotate(-90 35 35)" fill="#E6E6F3" />
                                                        <rect y="70" width="35" height="35" transform="rotate(-90 0 70)" fill="#F6F6F6" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12} className={`mb-3 mb-md-0 position-relative px-0`}>
                                            <div className={`rightaccount-box accountTwo p-5 mx-md-2 mx-0 reveal fade-bottom`}>
                                                <div className='my-3 px-lg-5'>
                                                    <span className='name'>Standard</span>
                                                </div>
                                                <div className='px-lg-5'>
                                                    <span className='amount'>N/A</span>
                                                </div>
                                                <div className='my-4 px-lg-5'>
                                                    <span className='minDeposit'>Min. Deposit</span>
                                                </div>
                                                <div className='mb-4 px-lg-5'>
                                                    <ul className='mx-0'>
                                                        <li className='mb-2 description'>Leverage <b>Up to 1:1000</b></li>
                                                        <li className='mb-2 description'><b>All</b> Market Available</li>
                                                        <li className='mb-2 description'><b>MT5</b> Platform</li>
                                                    </ul>
                                                </div>
                                                <div className='mx-lg-5'>
                                                    <Row className='px-0 mx-0'>
                                                        <Col lg={10} md={10} sm={12} xs={12} className='px-0'>
                                                            <Link to="/contact-us" className="text-decoration-none">
                                                                <div className='text-center redBtn px-1 py-3 h-100'>
                                                                    <span className='redBtnText'>{translate(props.lang, "Demo Account")}</span>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='active-left-corner'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                                                        <rect x="35" y="70" width="35" height="35" transform="rotate(180 35 70)" fill="#F9C258" />
                                                        <rect opacity="0.8" x="35" y="35" width="35" height="35" transform="rotate(180 35 35)" fill="#E6E6F3" />
                                                        <rect x="70" y="35" width="35" height="35" transform="rotate(180 70 35)" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className='inactive-left-corner'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                                                        <rect y="35" width="35" height="35" transform="rotate(-90 0 35)" fill="#FC0226" />
                                                        <rect opacity="0.8" x="35" y="35" width="35" height="35" transform="rotate(-90 35 35)" fill="#E6E6F3" />
                                                        <rect y="70" width="35" height="35" transform="rotate(-90 0 70)" fill="#F6F6F6" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <span className='question reveal fade-left'>{translate(props.lang, "Should you have any question about the plan?")} <Link to="/contact-us" className='text-red text-underline cursor-pointer'>{translate(props.lang, "Contact Us.")}</Link></span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(RightAccount);