import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

function GoldTrading(props) {
    return (
        <>
            <div className='py-5 goldtrading'>
                <div className='py-lg-5'>
                    <div className='reveal fade-left d-block d-md-none mb-4'>
                        <img src={require('../../../assets/img/market/goldtrading.png')} className='img-fluid' alt="gold trading" />
                    </div>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-center px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pr-md-3 text-center text-md-left mb-4 mb-md-0 d-none d-md-block'>
                                        <div className='reveal fade-left'>
                                            <img src={require('../../../assets/img/market/goldtrading.png')} className='img-fluid' alt="gold trading" />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 mx-0 pl-md-3'>
                                        <div className='reveal fade-right'>
                                            <span className='section-title'><span className='text-red'>Gold Trading</span></span>
                                        </div>
                                        <div className='mt-md-4 mb-md-4 mt-4 mb-0 reveal fade-right'>
                                            <span className='section-description lh-base'>Due to its intrinsic financial value and lack of a centralized regulation, Gold is considered a safe-haven asset that plays a vital role in the financial market ecosystem. Traders will typically invest in during times of market volatility to protect their funds, and our quick execution, cheap trading costs and 24/5-access to the markets provide the optimum conditions to trade Gold. Connect to the markets now and join the thousands of traders across the world that are already a part of the Bost community.</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(GoldTrading);