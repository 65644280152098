import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import ScrollToTop from "react-scroll-to-top";
import { connect } from 'react-redux';
import Footer from '../Component/Footer';
import TradeRiskFree from '../Component/TradeRiskFree';
import "../../../assets/css/trading/trading.scss";
import HeaderNavBar from '../Component/HeaderNavBar';
import TradingMarquee from '../Component/TradingMarquee';
import PageHeader from '../Component/PageHeader';
import BestExecution from './BestExecution';
import SEO from '../Component/Seo';
import RightAccount from '../Home/RightAccount';
import Deposit from './Deposit';

function Trading(props) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [history])
    return (
        <>
            <SEO
                title='Trading - Bost Forex'
                description='With tight spreads, high leverage and 24-hour access, explore how our different accounts can enhance your trading and dicover more about our markets, such as Gold and Forex.'
            />
            <HeaderNavBar />
            <div className='contents'>
                <div className="trading trading">
                    <PageHeader
                        title={<span className='head-big-title'>Trading <span className='text-yellow'>Overview</span></span>}
                        subtitle={<span className='content-text lh-base'>With tight spreads, high leverage and 24-hour access, explore how our different accounts can enhance your trading and dicover more about our markets, such as Gold and Forex.</span>}
                        joinUs={true}
                        demoAccount={true}
                    />
                    <RightAccount />
                    <Deposit />
                    <BestExecution />
                    <TradeRiskFree />
                </div>
                <Footer />
                <TradingMarquee />
                <ScrollToTop smooth className="scrolltotop d-flex align-items-center justify-content-center" component={<div><FaArrowUp color="#fff" /></div>} />
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Trading);