const FR = {
    "Home": "Accueil",
    "Trading": "Trading",
    "Markets": "Marchés",
    "Platforms & Tools": "Plateformes et Outils",
    "Contact Us": "Contactez-nous",
    "Create Account": "Créer un compte",
    "Demo Account": "Compte démo",
    "Commission - Free with No Deposit Fees.": "Sans commission - Aucun frais de dépôt.",
    "Open Your Account Now!": "Ouvrez votre compte maintenant!",
    "Trade Shares, Indices & Stocks": "Tradez des actions, indices et stocks",
    "With tight spreads, high leverage and quick execution of trades, we have well over a decade of experience connecting traders across the world to global markets. Whether you’re new to the markets or an experienced trader, we can offer you the ideal trading conditions and prices for you to effectively take on the markets. So, join the hundreds of thousands already a part of the Bost Forex network and allow us to connect you to the global markets.": "Avec des écarts serrés, un effet de levier élevé et une exécution rapide des transactions, nous avons plus d'une décennie d'expérience à connecter des traders du monde entier aux marchés mondiaux. Que vous soyez nouveau sur les marchés ou un trader expérimenté, nous pouvons vous offrir les conditions de trading et les prix idéaux pour vous permettre d'affronter efficacement les marchés. Alors, rejoignez les centaines de milliers de personnes déjà membres du réseau Bost Forex et laissez-nous vous connecter aux marchés mondiaux.",
    "All of our accounts offer trading through the MT5 platform, so you have the opportunity to use the world’s best trading technology to tackle the markets, regardless of experience.": "Tous nos comptes offrent le trading via la plateforme MT5, vous avez donc l'opportunité d'utiliser la meilleure technologie de trading au monde pour affronter les marchés, quel que soit votre niveau d'expérience.",
    "MT5 is a traditional platform that’s been fundamental to the success of professional traders for decades, but its efficient user interface combined with our comprehensive support resources mean it’s also ideal for those who are newer to the markets.": "MT5 est une plateforme traditionnelle qui est fondamentale pour le succès des traders professionnels depuis des décennies, mais son interface utilisateur efficace combinée à nos ressources de support complètes signifie qu'elle est également idéale pour ceux qui sont nouveaux sur les marchés.",
    "Automate your trade": "Automatisez vos transactions",
    "Simple-to-use interface": "Interface facile à utiliser",
    "Analyse the markets": "Analysez les marchés",
    "Create your own algorithms and automate your trades through customisable EAs (expert advisors).": "Créez vos propres algorithmes et automatisez vos transactions grâce à des EAs (conseillers experts) personnalisables.",
    "Navigate your way through the markets with the simple, efficient interface that MT5 offers.": "Naviguez sur les marchés avec l'interface simple et efficace qu'offre MT5.",
    "Use the array of analytical tools available to you and analyse every aspect of any market you trade.": "Utilisez la gamme d'outils d'analyse disponibles et analysez chaque aspect de tout marché que vous tradez.",
    "FOREX TRADING": "TRADING FOREX",
    "GOLD TRADING": "TRADING OR",
    "INDICES TRADING": "TRADING INDICES",
    "ENERGY TRADING": "TRADING ÉNERGIE",
    "With over $4 trillion of Forex traded daily, currency pairs are some of the most liquid markets to trade.": "Avec plus de 4 000 milliards de dollars de Forex échangés quotidiennement, les paires de devises sont parmi les marchés les plus liquides à trader.",
    "Seen a safe-haven assets, Metals are traders’ go-to investment when heavy volatility hits the markets.": "Considérés comme des valeurs refuges, les métaux sont l'investissement privilégié des traders lorsque la forte volatilité frappe les marchés.",
    "From Wall Street to the FTSE, trade all the major stock Indices from across the world.": "De Wall Street au FTSE, tradez tous les principaux indices boursiers du monde entier.",
    "With strong political and environmental significance, embrace the volatility of the Energies markets.": "Avec une forte signification politique et environnementale, profitez de la volatilité des marchés de l'énergie.",
    "Leverage 1:1000": "Levier 1:1000",
    "0.005 Execution Speed": "Vitesse d'exécution 0,005",
    "Spreads from 0.5 Pips": "Spreads à partir de 0,5 pips",
    "No hidden fees": "Aucun frais caché",
    "Gain greater exposure to the markets with our high leverage of up to 1:1000.": "Obtenez une plus grande exposition aux marchés avec notre fort effet de levier jusqu'à 1:1000.",
    "Nullify the impact of slippage and get exact prices with our instant execution on all trades.": "Annulez l'impact du glissement et obtenez des prix exacts avec notre exécution instantanée sur toutes les transactions.",
    "Starting from 0.5 pips, trade with some of the tightest spreads in the industry.": "À partir de 0,5 pips, tradez avec certains des spreads les plus serrés de l'industrie.",
    "Enjoy trading all the most popular, global markets without any hidden fees.": "Profitez de trader sur tous les marchés mondiaux les plus populaires sans aucun frais caché.",
    "Choose your account type and start trading today!": "Choisissez votre type de compte et commencez à trader dès aujourd'hui!",
    "or": "ou",
    "register for free demo.": "inscrivez-vous pour une démo gratuite.",
    "Should you have any question about the plan?": "Avez-vous des questions sur le plan?",
    "Contact Us.": "Contactez-nous.",
    "Demo Account": "Compte démo",
    "Find the right account for you.": "Trouvez le bon compte pour vous.",
    "Try it out with our free Demo account.": "Essayez-le avec notre compte démo gratuit.",
    "Trade over 50 markets on MT5 now with our Standard account and experience all the thrills of live trading.Not ready to trade for real?": "Tradez plus de 50 marchés sur MT5 maintenant avec notre compte Standard et vivez toutes les sensations du trading en direct. Pas prêt à trader pour de vrai?",
    "Learn More": "En savoir plus",
    "TRADE RISK-FREE WITH OUR DEMO ACCOUNT": "TRADEZ SANS RISQUE AVEC NOTRE COMPTE DÉMO",
    "Connect to all the major markets in the MT5 environment with our Demo account, and experience the markets risk-free.": "Connectez-vous à tous les principaux marchés dans l'environnement MT5 avec notre compte démo, et découvrez les marchés sans risque.",
    "Register For A Demo Account": "Inscrivez-vous pour un compte démo",
    "Free 30 days Trial  |  Exclusive Support  |  No Fees": "Essai gratuit de 30 jours  |  Support exclusif  |  Sans frais",
    "For general enquiries": "Pour les demandes générales",
    "Your phone number": "Votre numéro de téléphone",
    "Full Name": "Nom complet",
    "Your e-mail": "Votre e-mail",
    "Your message": "Votre message",
    "Submit": "Soumettre",
    "Say something": "Dites quelque chose",
    "Need a hand?": "Besoin d'aide ?",
    "Don’t hesitate to get in touch with us for any of your trading enquiries.": "N'hésitez pas à nous contacter pour toutes vos questions sur le trading.",

}

export default FR;