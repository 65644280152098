import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { useSwipeable } from 'react-swipeable';
import "../../../assets/css/home/experience.scss";
import { useState } from 'react';
import { translate } from 'views/utils/translate';

const list = [
    { name: 'Automate your trade', description: 'Create your own algorithms and automate your trades through customisable EAs (expert advisors).', icon: require('../../../assets/img/home/automate.svg').default },
    { name: 'Simple-to-use interface', description: 'Navigate your way through the markets with the simple, efficient interface that MT5 offers.', icon: require('../../../assets/img/home/interface.svg').default },
    { name: 'Analyse the markets', description: 'Use the array of analytical tools available to you and analyse every aspect of any market you trade.', icon: require('../../../assets/img/home/markets.svg').default },
]

function Experience(props) {
    const [pageNumber, setPageNumber] = useState(1);

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const renderPagination = () => {
        var elements = [];
        for (let i = 0; i < list.length; i++) {
            elements.push(
                <div key={i} className={`mr-2 page-bullet ${pageNumber - 1 === i ? 'active' : ''}`}></div>
            );
        }
        return elements;
    }

    const nextSlide = () => {
        if (pageNumber !== list.length) {
            setPageNumber(pageNumber + 1);
        } else if (pageNumber === list.length) {
            setPageNumber(1);
        }
    }

    const prevSlide = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        } else {
            setPageNumber(list.length);
        }
    }
    return (
        <>
            <div className='py-5 experience position-relative'>
                <div className='py-lg-5'>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-center px-0 mx-0 h-100'>
                                    <Col lg={6} md={4} xs={12} sm={12} className='px-0 px-md-3 text-center text-md-left mb-4 mb-md-0'>
                                        <div className='reveal fade-left text-center'>
                                            <img src={require('../../../assets/img/home/phone.png')} className='img-fluid experience-phone' alt="experience" />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={8} sm={12} xs={12} className='px-0 px-md-3 mx-0'>
                                        <div className='reveal fade-right'>
                                            <span className='section-title'>Experience the World’s </span>
                                            <span className='section-title'><span className='text-red d-block'>No.1 trading platform.</span></span>
                                        </div>
                                        <div className='my-4 reveal fade-right'>
                                            <span className='section-description lh-base d-block mb-3'>{translate(props.lang, "All of our accounts offer trading through the MT5 platform, so you have the opportunity to use the world’s best trading technology to tackle the markets, regardless of experience.")}</span>
                                            <span className='section-description lh-base'>{translate(props.lang, "MT5 is a traditional platform that’s been fundamental to the success of professional traders for decades, but its efficient user interface combined with our comprehensive support resources mean it’s also ideal for those who are newer to the markets.")}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <div className='infoBox-absolute d-md-block d-block'>
                            {/* <Container className='experience'> */}
                                <Row className='justify-content-center px-0 mx-0 align-items-stretch'>
                                    {list.map((item, i) => (
                                        <Col lg={4} md={4} sm={12} xs={12} key={i} className='mb-3 mb-md-0 px-0 px-md-3'>
                                            <div className='infoBox px-4 py-5 h-100 reveal fade-bottom'>
                                                <div className='infoBox-iconContainer'>
                                                    <img src={item.icon} className='icon' alt={item.name} />
                                                </div>
                                                <div className='infoBox-name-container my-4'>
                                                    <span className='infoBox-name'>{translate(props.lang, item.name)}</span>
                                                </div>
                                                <div>
                                                    <span className='infoBox-content lh-base'>{translate(props.lang, item.description)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            {/* </div> */}
                            {/* </Container> */}
                        </div>

                        {/* <div className='d-block d-md-none position-relative'  {...handlers}>
                            <Row className='mt-5 justify-content-center mx-0 px-0 reveal fade-bottom'>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                    <div className='infoBox px-4 py-5 h-100 reveal fade-bottom'>
                                        <div className='infoBox-iconContainer'>
                                            <img src={list[pageNumber - 1].icon} className='icon' alt={list[pageNumber - 1].name} />
                                        </div>
                                        <div className='infoBox-name-container my-4'>
                                            <span className='infoBox-name'>{translate(props.lang, list[pageNumber - 1].name)}</span>
                                        </div>
                                        <div>
                                            <span className='infoBox-content lh-base'>{translate(props.lang, list[pageNumber - 1].description)}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className='pagination text-center d-flex justify-content-center align-items-center w-100 mt-2'>
                                {list.length > 0 ? renderPagination() : ''}
                            </div>
                        </div> */}
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Experience);