import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import "../../../assets/css/common/traderiskfree.scss";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { translate } from 'views/utils/translate';
import { connect } from 'react-redux';

function TradeRiskFree(props) {
    return (
        <>
            <div className='traderiskfree'>
                <div className='overlay'></div>
                <Container className='h-100 py-5'>
                    <Row className='mx-0 px-0 h-100 align-items-lg-center aligns-items-end'>
                        <Col lg={5} md={12} xs={12} sm={12} className='px-0 mx-0 reveal fade-bottom'>
                            <div className='d-flex align-items-end align-items-md-center pb-lg-0 w-100 h-100'>
                                <div className='pb-lg-0 w-100'>
                                    <div className='text-center text-lg-left'>
                                        <span className='tradeTitle d-block'>{translate(props.lang, "TRADE RISK-FREE WITH OUR DEMO ACCOUNT")}</span>
                                    </div>
                                    <div className='my-3 my-lg-5 text-center text-lg-left'>
                                        <span className='tradeSubTitle'>{translate(props.lang, "Connect to all the major markets in the MT5 environment with our Demo account, and experience the markets risk-free.")}</span>
                                    </div>
                                    <div className='w-100'>
                                        <Link to="/contact-us" className="text-decoration-none">
                                            <div className='startBtn px-5 py-3 d-block text-center mx-md-auto mx-lg-0'>
                                                <span className='startBtnText'>{translate(props.lang, "Register For A Demo Account")}</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='my-3 my-lg-5 text-center text-lg-left'>
                                        <span className='tradeSubTitle'>{translate(props.lang, "Free 30 days Trial  |  Exclusive Support  |  No Fees")}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    }
}
export default connect(mapStateToProps)(TradeRiskFree);