import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import ScrollToTop from "react-scroll-to-top";
import { connect } from 'react-redux';
import Footer from '../Component/Footer';
import TradeRiskFree from '../Component/TradeRiskFree';
import "../../../assets/css/market/market.scss";
import HeaderNavBar from '../Component/HeaderNavBar';
import TradingMarquee from '../Component/TradingMarquee';
import PageHeader from '../Component/PageHeader';
import WhyTrade from './WhyTrade';
import GoldTrading from './GoldTrading';
import ForexTrading from './ForexTrading';
import IndicesTrading from './IndicesTrading';
import EnergyTrading from './EnergyTrading';
import SEO from '../Component/Seo';

function Market(props) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [history])
    return (
        <>
            <SEO
                title='Markets - Bost Forex'
                description='From Gold to Forex pairs, we offer over 50 of the most popular markets. Allow us to connect you to them and help you discover your next trading opportunity.'
            />
            <HeaderNavBar />
            <div className='contents'>
                <div className="market market">
                    <PageHeader
                        title={<span className='head-big-title'><span className='text-yellow'>Markets</span></span>}
                        subtitle={<span className='content-text lh-base'>From Gold to Forex pairs, we offer over 50 of the most popular markets. Allow us to connect you to them and help you discover your next trading opportunity.</span>}
                        joinUs={true}
                        demoAccount={true}
                    />
                    <WhyTrade />
                    <ForexTrading />
                    <GoldTrading />
                    <IndicesTrading />
                    <EnergyTrading />
                    <TradeRiskFree />
                </div>
                <Footer />
                <TradingMarquee />
                <ScrollToTop smooth className="scrolltotop d-flex align-items-center justify-content-center" component={<div><FaArrowUp color="#fff" /></div>} />
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Market);