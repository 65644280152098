import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Container, Row } from 'reactstrap';

function WhyTrade(props) {
    return (
        <>
            <div className='whytrade py-5'>
                <Container className='h-100 d-flex flex-column'>
                    <Row className="px-0 mx-0 justify-content-center align-items-center mt-lg-5">
                        <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                            <div className='position-relative'>
                                <Row className='contentbg px-0 mx-0'>
                                    <Col lg={6} md={12} sm={12} xs={12} className='order-2 order-md-1'>
                                        <Row className='px-0 mx-0 justify-content-center align-items-center py-5 w-100 h-100 reveal fade-left'>
                                            <Col lg={11} md={11} xs={12} sm={12}>
                                                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                                                    <div className='phone-container text-center reveal zoom-in'>
                                                        <img src={require('../../../assets/img/market/computer.png')} className='img-fluid computer' alt="phone" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className='order-1 order-md-2'>
                                        <Row className='px-0 mx-0 py-5 justify-content-center align-items-center w-100 h-100 reveal fade-right'>
                                            <Col lg={11} md={11} xs={12} sm={12}>
                                                <div>
                                                    <span className="section-title">Why trade <span className='text-gold d-block'>Forex with Bost?</span></span>
                                                </div>
                                                <div className='my-4'>
                                                    <span className="section-content">Our tight spreads, mobile and desktop trading capabilities and 24/5-access to the markets keeps you connected, so you can trade with us anytime, anywhere on MT5, the world’s number one trading platform. We’ll be with you every step of the way to help you master MT5, analyse the markets and identify trading opportunities. Create an account now and join thousands of traders across the world that are already a part of the Bost community.</span>
                                                </div>
                                                <Row className='px-0 mx-0 w-100 h-100 justify-content-start'>
                                                    <Col lg={6} md={6} sm={12} xs={12} className='pl-0 pr-0 pl-md-0 h-100 pr-md-2 mb-lg-0 mb-3'>
                                                        <Link to="/contact-us" className="text-decoration-none">
                                                            <div className='text-center yellowBtn px-1 py-3 h-100 '>
                                                                <span className='yellowBtnText'>Create Account</span>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12} className='pl-0 pr-0 pr-md-0 h-100 pl-md-2'>
                                                        <Link to="/contact-us" className="text-decoration-none">
                                                            <div className='text-center redBtn px-1 py-3 h-100'>
                                                                <span className='redBtnText'>Demo Account</span>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(WhyTrade);