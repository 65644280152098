/*eslint-disable*/
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import menu from "views/utils/menu2";
import "../../../assets/css/common/footer.scss";
import { translate } from 'views/utils/translate';
import { connect } from 'react-redux';

function Footer(props) {
    const location = useLocation();
    return (
        <>
            <footer className={`footers pb-5`}>
                <Container className="py-5">
                    <Row className="justify-content-start align-items-center mx-0 px-0">
                        <Col lg={2} md={2} sm={12} xs={12} className="px-0 mx-md-0 reveal fade-top">
                            <div className="text-center text-md-left d-none d-md-block">
                                <img src={require('../../../assets/img/logo/logo-word.png')} className="img-fluid" alt="bost technology" />
                            </div>
                            <div className="text-left py-md-5 d-block d-md-none">
                                <div>
                                    <img src={require('../../../assets/img/logo/logo-word.png')} className="logo-mobile" alt="bost technology" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-start my-5">
                    {
                            menu.map((item, i) => (
                            <Col lg={2} md={2} sm={12} xs={12} key={i}>
                                <Link to={item.pathName} className="text-decoration-none d-block d-inline-md-block px-0 mx-0 mb-lg-0 mb-3 footer-link reveal fade-top mr-lg-4 cursor-pointer">
                                    <Row className="mx-0 px-0">
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0 mx-md-0">
                                            <div className="text-decoration-none">
                                                <span className={`parentMenu ${item.pathName === location.pathname ? 'active': ''}`}>{translate(props.lang, item.menu)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                            ))
                        }
                    </Row>
                    
                    <Row className="justify-content-center align-items-center mx-0 px-0 mt-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="px-0 mx-md-0">
                            {/* <div className="text-left text-md-left reveal fade-left mb-2">
                                <span className={`copyright bold`}>Bost Technology Pty Limited is registered with the Australian Securities and Investments Commission (ASIC) under AFS Representative Number 001306492.</span>
                            </div> */}
                            <div className="text-left text-md-left reveal fade-left">
                                <span className={`copyright bold d-block`}>Bost Technology Limited (2636420)</span>
                                <span className={`copyright bold d-block`}>Registered Address: 6/F, Manulife Place, 348 Kwun Tong Road, Kl, Hong Kong.</span>
                                <span className={`copyright bold d-block`}>Physical Address: A-32-8, 5 A Menara UOA Bangsar-H TWR, JLN Bangsar UTAMA 1, Bangsar, 59000 Kuala Lumpur, Malaysia.</span>
                                <span className={`copyright bold d-block`}>Telephone: +603-27702749</span>
                                <span className={`copyright bold d-block text-lowercase`}>support@bostforex.com</span>
                            </div>
                            <div>
                                <hr className="redBottomDivider d-block" />
                            </div>
                            <div className="text-justify text-md-left reveal fade-left mt-4">
                                <span className={`copyright`}>Risk Warning: Trading forex, CFDs, and any financial derivative instruments on margin carries a high level of risk and may not be suitable for all investors, as you could sustain losses in excess of your deposits. Leverage can work for you as well as against you, please be aware and fully understand all risks associated with the market conditions and trading terms. Please carefully consider your financial situation and investment experience prior to proceeding in trading any products offered by Bost Technology Limited (Bost). Bost assumes no liability for errors, inaccuracies or omissions; does not warrant the accuracy, completeness of information, text, graphics, links or other items contained within this site. The contents in this site are provided only for information purposes and they should not be regarded as an offer or solicitation to any person in any jurisdiction where such an offer or solicitation is not authorized; nor should any of the contrary to local law or regulation. Read and understand the Terms and Conditions on Bost website prior to initiating your leveraged investment.</span>
                            </div>
                            <div className="text-justify text-md-left mt-4 reveal fade-left">
                                <span className={`copyright`}>Disclaimer: This information is not directed or intended for distribution to or use by residents of certain countries/jurisdictions including, but not limited to, Australia, Belgium, Iran, Japan, North Korea and USA. The Company does not offer its services to residents of certain countries/jurisdictions including, but not limited to, Australia, Belgium, Iran, Japan, North Korea and USA. The Company holds the right to alter the above lists of countries at its own discretion.</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="px-0 mx-md-0 mt-4">
                            <div className="text-center text-md-left">
                                <span className={`copyright`}>Copyright © 2024 Bost Technology Limited. All rights reserved.</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Footer);