import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'views/utils/translate';

function Connecting(props) {
    return (
        <>
            <div className='connecting d-flex align-items-center position-relative'>
                <Container className='h-100'>
                    <Row className='mx-0 px-0 align-items-center h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='mx-0 px-0 align-items-lg-center align-items-center justify-content-center h-100'>
                                <Col lg={9} md={10} xs={12} sm={12} className='px-0 mx-0 mb-lg-5 highresscreen'>
                                    <div className='reveal fade-left text-md-center text-left'>
                                        <span className='section-title'>connecting you with the <span className='text-red'>Platforms, products & people </span>you need to trade smart.</span>
                                    </div>
                                </Col>
                                <Col lg={10} md={12} sm={12} xs={12} className='mt-5 px-0'>
                                    <div className='text-md-center text-left reveal fade-right'>
                                        <span className='section-content'>{translate(props.lang, "With tight spreads, high leverage and quick execution of trades, we have well over a decade of experience connecting traders across the world to global markets. Whether you’re new to the markets or an experienced trader, we can offer you the ideal trading conditions and prices for you to effectively take on the markets. So, join the hundreds of thousands already a part of the Bost Forex network and allow us to connect you to the global markets.")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Connecting);