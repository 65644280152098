import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import React, { useState } from 'react';

const list = [
    { key: '1', title: 'When can I trade Energies?', description: 'All Energy markets we offer are available to trade from Monday to Friday, however the specific times that the markets are open differ from Energy to Energy. For a full breakdown, visit our Energy market page.' },
    { key: '2', title: 'What are the major Energies I can trade with Bost Forex?', description: 'At Bost, we offer three Energy markets. These are USOUSD, UKOUSD and XNGUSDgmp. For more information, visit our Energy market page.' },
    { key: '3', title: 'Are there any hidden fees when trading Energies?', description: 'There are no hidden fees when trading Energies with Bost.' },
    { key: '4', title: 'What are the general costs of trading Energies with Bost Forex?', description: 'With certain markets, you may be subject to overnight fees but we ensure all our costs remain highly competitive within the industry.' },
    { key: '5', title: 'Do you offer dedicated customer support for Energy trading?', description: 'Yes, we are always available for any questions or queries you might have about your trading. Feel free to get in touch on…' },
]


function EnergyTrading(props) {
    const [selectedItem, setSelectedItem] = useState(['1']);

    const openAccordion = (id) => {
        let newArray = selectedItem;
        if (newArray.includes(id)) {
            newArray = newArray.filter(item => item !== id);
            setSelectedItem(newArray);
        } else {
            newArray = newArray.concat(id);
            setSelectedItem(newArray);
        }
    }
    return (
        <>
            <div className='py-5 energytrading'>
                <div className='py-lg-5'>
                    <div className='reveal fade-left mb-4 d-block d-md-none'>
                        <img src={require('../../../assets/img/market/energytrading.png')} className='img-fluid' alt="forex trading" />
                    </div>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            {/* <Col lg={12} md={12} sm={12} xs={12} className='px-0 pr-md-3 mx-0 mb-4 d-block d-md-none'>
                                <div className='reveal fade-left mb-4 d-block d-md-none'>
                                    <img src={require('../../../assets/img/market/energytrading.png')} className='img-fluid' alt="forex trading" />
                                </div>
                            </Col> */}
                            <Col lg={11} md={11} xs={12} sm={12} className='px-0'>
                                <div className='text-left text-md-center reveal fade-left'>
                                    <span className='section-title'><span className="text-red">Energy Trading</span></span>
                                </div>
                                <div className='text-left text-md-center reveal fade-left my-4'>
                                    <span className='section-description'>Due to their strong connection with environmental and political activity, Energies experience high volatility and unpredictable swings – making them an attractive market to trade for those with a greater appetite for risk. Our tight spreads, mobile trading and desktop trading capabilities as well as 24/5-access to the markets keeps you connected, so you can trade Energies anytime, anywhere on MT5, the world’s number one trading platform. We’ll be with you every step of the way to help you master MT5, analyse the markets and identify energies trading opportunities. Create an account now and join the thousands of traders across the world that are already a part of the Bost community.</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-start px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 pr-md-3 mx-0 mt-md-5 d-none d-md-block'>
                                        <div className='reveal fade-left'>
                                            <img src={require('../../../assets/img/market/energytrading.png')} className='img-fluid' alt="forex trading" />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pl-md-3 text-center text-md-right mb-md-0 mt-md-5 mt-4'>
                                        <div className='reveal fade-bottom'>
                                            <Accordion allowMultipleExpanded={true} className="text-left">
                                                {list.map((item, i) => (
                                                    <AccordionItem key={i} className={`border-none accordion-item ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>
                                                        <AccordionItemHeading className='px-0'>
                                                            <AccordionItemButton className={`px-0`}>
                                                            <Row className={`mx-0 px-0 justify-content-center align-items-center ${selectedItem.includes(item.key) ? i !== 0 ? 'pt-3' : 'pt-0' : 'py-3'}`} onClick={() => openAccordion(item.key)}>
                                                            <Col lg={10} md={10} sm={11} xs={11} className="px-0">
                                                                        <div>
                                                                            <span className={`faq-title ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>Q{item.key}. {item.title}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                                                        <div className="text-end">
                                                                            {
                                                                                selectedItem.includes(item.key) ? (<img src={require('../../../assets/img/common/minus.svg').default} className='expand-arrow' alt="up" />) : <img src={require('../../../assets/img/common/plus.svg').default} className='expand-arrow' alt="down" />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={`px-0 ${selectedItem.includes(item.key) ? "d-block" : "d-none"}`}>
                                                            <div className="accordionContent px-0">
                                                                <Row className="mx-0 px-0 align-items-center">
                                                                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                                        <div className={`${selectedItem.includes(item.key) ? 'py-3' : 'py-3' } `}>
                                                                            <span className='faq-description'>{item.description}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}

                                            </Accordion>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(EnergyTrading);