const menu = [
    { 
        menu: "Home", 
        pathName: '/home',
        allPath: ['/home'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Trading", 
        pathName: '/trading',
        allPath: ['/trading'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Markets", 
        pathName: '/markets',
        allPath: ['/markets'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Platforms & Tools", 
        pathName: '/platform',
        allPath: ['/platform'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Contact Us", 
        pathName: '/contact-us',
        allPath: ['/contact-us'],
        display: ["all"],
        children: [],
    }
]

export default menu;