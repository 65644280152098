import React from 'react'
import { connect } from 'react-redux';
import { TickerTape } from "react-ts-tradingview-widgets";

function TradingMarquee(props) {
    return (
        <>
            <div className="trading-marquee d-flex align-items-center w-100">
                <div className='w-100'>
                    <TickerTape colorTheme="dark" isTransparent={true} displayMode="compact" showSymbolLogo={true} />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
      lang: i18n.lang
    }
  }
export default connect(mapStateToProps)(TradingMarquee);