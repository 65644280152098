import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import React, { useState } from 'react';

const list = [
    { key: '1', title: 'What Forex pairs can I trade with Bost?', description: 'You can trade all the majors, as well as over 50 other Forex pairs all with tight spreads and high leverage with Bost.' },
    { key: '2', title: 'When does the Forex market open?', description: 'The Forex market on MT5 opens at 00:05 on Monday, and closes at 23:59 on a Friday. Some of the minor Forex pairs open at the slightly later time of 01:00 on Monday morning.' },
    { key: '3', title: 'How does a trade work?', description: 'To place a trade, you must either decide to open a buy or sell position. A buy position means you want the price of the traded asset to rise for you to make a profit. A sell position means you want the price to fall. You have the choice of when you want to close that position, and at that point your profit or loss will be realised.' },
    { key: '4', title: 'How do I place a trade on foreign currency?', description: 'To place a Forex trade, first you must download the MT5 trading platform if you haven’t already. Then, simply search for the Forex pair you’re intending to trade in the search bar and select open a position from the menu bar.' },
    { key: '5', title: 'What information do I need to open an account with Bost?', description: 'To open a Bost account, all you will need is a form of identification that can be sent to us so we can verify your identity. Apart from that, simply go through our sign-up form and provide us with some personal information such as your name, date of birth, country of residence etc.' },
]

function ForexTrading(props) {
    const [selectedItem, setSelectedItem] = useState(['1']);

    const openAccordion = (id) => {
        let newArray = selectedItem;
        if (newArray.includes(id)) {
            newArray = newArray.filter(item => item !== id);
            setSelectedItem(newArray);
        } else {
            newArray = newArray.concat(id);
            setSelectedItem(newArray);
        }
    }
    return (
        <>
            <div className='py-5 forextrading'>
                <div className='py-lg-5'>
                    <div className='reveal fade-left mb-4 d-block d-md-none'>
                        <img src={require('../../../assets/img/market/forextrading.png')} className='img-fluid' alt="forex trading" />
                    </div>
                    <Container className='h-100'>
                        <Row className="px-0 mx-0 justify-content-center align-items-center h-100">
                            <Col lg={7} md={10} xs={12} sm={12} className='px-0'>
                                <div className='text-left text-md-center reveal fade-left'>
                                    <span className='section-title'><span className="text-red">Forex Trading</span></span>
                                </div>
                                <div className='text-left text-md-center reveal fade-left my-4'>
                                    <span className='section-description'>Choose from over 40 Forex currency pairs, such as USDJPY and GBPUSD, and trade the world’s most volatile markets with tight spreads, high leverage and local support.</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} xs={12} sm={12} className='px-0'>
                                <Row className='justify-content-start align-items-start px-0 mx-0 h-100'>
                                    <Col lg={6} md={6} sm={12} xs={12} className='px-0 pl-md-3 mx-0 order-2 order-lg-1 mt-md-5 mt-4 mb-md-0'>
                                        <div className='reveal fade-bottom'>
                                            <Accordion allowMultipleExpanded={true} className="text-left">
                                                {list.map((item, i) => (
                                                    <AccordionItem key={i} className={`border-none accordion-item ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>
                                                        <AccordionItemHeading className='px-0'>
                                                            <AccordionItemButton className={`px-0`}>
                                                            <Row className={`mx-0 px-0 justify-content-center align-items-center ${selectedItem.includes(item.key) ? i !== 0 ? 'pt-3' : 'pt-0' : 'py-3'}`} onClick={() => openAccordion(item.key)}>
                                                            <Col lg={10} md={10} sm={11} xs={11} className="px-0">
                                                                        <div>
                                                                            <span className={`faq-title ${selectedItem.includes(item.key) ? 'active' : 'inactive'}`}>Q{item.key}. {item.title}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                                                        <div className="text-end">
                                                                            {
                                                                                selectedItem.includes(item.key) ? (<img src={require('../../../assets/img/common/minus.svg').default} className='expand-arrow' alt="up" />) : <img src={require('../../../assets/img/common/plus.svg').default} className='expand-arrow' alt="down" />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={`px-0 ${selectedItem.includes(item.key) ? "d-block" : "d-none"}`}>
                                                            <div className="accordionContent px-0">
                                                                <Row className="mx-0 px-0 align-items-center">
                                                                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                                        <div className={`${selectedItem.includes(item.key) ? 'py-3' : 'py-3' } `}>
                                                                            <span className='faq-description'>{item.description}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}

                                            </Accordion>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} sm={12} className='px-0 pr-md-3 mt-5 text-center text-md-right order-1 order-lg-2 mb-md-0 d-none d-md-block'>
                                        <div className='reveal fade-left'>
                                            <img src={require('../../../assets/img/market/forextrading.png')} className='img-fluid' alt="forex trading" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ForexTrading);